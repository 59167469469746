@import ../environment

$horizontal-from: $horizontal-dashboard-hero-from

.dashboard-hero-container
  --padding-left: var(--container-padding)
  --padding-right: var(--container-padding)
  --container-content-width: calc(100% - var(--padding-left) - var(--padding-right)) /* 100% - padding because 100% references the grid container which has full width */

  display: grid

  grid-template-columns: 100%
  grid-template-rows: 1fr
  grid-template-areas: "title" "image" "course-units"

  width: 100%

  gap: var(--spacer-2) var(--grid-gutter-width)
  padding: 0 var(--padding-right) 0 var(--padding-left)

  +media-breakpoint-up($tablet-from)
    padding-bottom: spacer(3)
    --course-unit-tile--size: #{px-to-rem(200px)}

  +media-breakpoint-up($horizontal-from)
    // horizontal layout grows to the right end of the viewport, but starts where regular .container contents start
    --padding-right: 0px
    --left-side-width: #{col-width(4)}
    --right-side-width: #{col-width(8)}

    grid-template-columns: var(--left-side-width) var(--right-side-width)
    grid-template-rows: auto 1fr
    grid-template-areas: "title course-units" "image course-units"

    +media-breakpoint-up($desktop-from)
      // on desktop, layout is (somewhat) like a .container (no longer extends to the right end of the viewport)
      // course units slider will extend a bit further, or shrink, if necessary. see course_units_scroller.sass.
      $scroller-button-area-size: $course-unit-scroller-button-size + 2 * $course-unit-scroller-button-gap

      --course-units-scroller--drawable-overflow: calc(var(--grid-gutter-width) / 2)
      --padding-right: var(--container-padding)
      --container-content-width: 100%
      --course-unit-tiles-container-width: calc(100vw * 8 / #{$grid-columns} - var(--padding-left) - var(--padding-right) - var(--grid-gutter-width) * 0.75) /* not sure about .75, but .5 is not enough and 1 is too much ¯\_(ツ)_/¯ */

      // like a col-4 = 3 columns
      --course-unit-tile--size: #{col-width(4, var(--course-unit-tiles-container-width))}

      @media (min-width: 1330px)
        // like a col-3 = 4 columns
        --course-unit-tile--size: #{col-width(3, var(--course-unit-tiles-container-width))}

        @media (min-width: $container-max-width-incl-padding + $scroller-button-area-size)
          --course-unit-tile--size: #{col-width(2, $container-content-max-width)}

      grid-template-rows: auto 1fr
      grid-template-areas: "title course-units" "image course-units"

      max-width: $container-max-width-incl-padding
      margin-inline: auto

      padding-bottom: 0


.dashboard-hero-container--title
  grid-area: title

  +media-breakpoint-down($horizontal-from)
    text-align: center
    br
      display: none


.dashboard-hero-container--image
  grid-area: image

  display: grid
  align-items: center
  justify-items: center

  z-index: 1


.dashboard-hero-container--course-units
  grid-area: course-units

@import ../environment

$icon-padding: px-to-rem(12px)
$element-padding: px-to-rem(20px)
$icon-size: px-to-rem(48px)

.user-test-card
  display: flex
  align-items: center
  gap: $element-padding

  border: $border-width solid $gray-300
  border-radius: $border-radius

  padding: $element-padding

  +media-breakpoint-down(lg)
    flex-wrap: wrap

  & + &
    margin-top: var(--grid-gutter-width)

  &.-clickable
    cursor: pointer
    transition: brand-transition(transform)

    &:hover
      background-color: $gray-200

    &:active
      background-color: $gray-200
      transform: scale(0.96)


.user-test-card--icon
  flex: 0 0 auto
  width: $icon-size
  height: $icon-size

  color: $brand-green
  background-color: $brand-green-50
  border-radius: 100vmax

  display: grid
  place-content: center

  --app-icon-size: 28px

.user-test-card--actions
  display: flex
  align-items: center

  +media-breakpoint-down(lg)
    width: 100%

.user-test-card--text
  flex-grow: 1

  +media-breakpoint-down(lg)
    flex-grow: 0
    width: calc(100% - 48px - var(--grid-gutter-width))

.user-test-card--title
  font-size: $lead-font-size
  font-weight: $font-weight-bold
  color: $brand-dark-blue

  &:hover
    --bs-link-color: #{$brand-dark-blue}
    --bs-link-hover-color: #{$brand-dark-blue}

.user-test-card--state
  color: $brand-orange
  &.-muted
    color: $gray-600

=horizontal
  @media (min-width: 576px)
    @content

.error-page
  display: grid
  gap: var(--spacer-3) 0

  grid-template-areas: "heading" "image" "message" "actions"
  justify-items: center

  +horizontal
    grid-template-columns: auto var(--grid-gutter-width) auto
    grid-template-rows: auto auto 1fr
    grid-template-areas: "image . heading" "image . message" "image . actions"
    justify-content: center
    justify-items: start

  margin-inline: auto

.error-page--image
  grid-area: image
  width: 10rem
  text-align: center

  > img
    width: 100%

.error-page--heading
  grid-area: heading

  color: var(--bs-danger)
  margin: 0

.error-page--message
  grid-area: message

.error-page--actions
  grid-area: actions
  align-self: start

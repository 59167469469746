@import ../environment

.floating-navigation
  display: flex

  font-weight: $font-weight-bold
  font-size: $small-font-size
  color: $navbar-light-color
  background-color: $white
  box-shadow: $shadow-lg

  padding: spacer(2)

  +media-breakpoint-up($tablet-from)
    padding: spacer(2) spacer(4)
    border-radius: 100vmax
    width: 25rem
    max-width: 90vw

.floating-navigation--link
  --app-icon-size: #{px-to-rem(36px)}

  flex: 1 1 100%

  display: grid
  gap: spacer(1)
  justify-items: center

  color: $navbar-light-color
  transition: $nav-link-transition

  &:hover,
  &:focus
    color: $navbar-light-hover-color

  &:active,
  &.active
    color: $navbar-light-active-color

  &.-open-search
    body.-search-shown &
      display: none
  &.-close-search
    body:not(.-search-shown) &
      display: none

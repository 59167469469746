@import ../environment
@import ../config/authentication_box

.authentication-error
  +authentication-box

.authentication-error--heading
  +authentication-box--heading($margin: $size-xs, $color: $danger)

.authentication-error--buttons
  +authentication-box--buttons($margin: $size-s)

@import ../environment

$large-image-size: 300px
$medium-image-size: 170px

.grid-game-nugget
  display: grid
  align-items: center

  --nugget-border-color: var(--nugget-color, #{$white})

  background-color: $white
  border: 2px solid var(--nugget-border-color)

  border-radius: $border-radius-xs
  user-select: none

  padding: var(--spacer-1)
  +media-breakpoint-up($tablet-from)
    padding: var(--spacer-2) var(--spacer-3)

  transition: brand-transition(background-color, border-color)

  &.-image
    padding: 1px

    &.-large
      img
        --max-height: #{$large-image-size}

    &.-medium
      img
        --max-height: #{$medium-image-size}

    img
      --max-height: #{$size-2xl}
      +media-breakpoint-up($tablet-from)
        --max-height: #{$size-4xl}

      border-radius: $border-radius-xs * .5
      width: 100%
      max-width: calc(var(--max-height) * 16 / 9)
      max-height: var(--max-height)
      object-fit: contain

      +media-breakpoint-up($tablet-from)
        height: var(--max-height)

    .grid-game-modal &
      max-width: 35vw


  &.-draggable
    --nugget-border-color: var(--nugget-color, #{$brand-green-75})
    background-color: var(--nugget-background, $brand-green-75)
    cursor: var(--nugget-cursor, move)

  +dragged-nugget-styles


@keyframes nugget-placed
  0%
    opacity: 0.5
    scale: 1.05
    border-color: $brand-green
    box-shadow: $shadow-dragging

  100%
    scale: 1
    opacity: 1
    border-color: $brand-green-50
    box-shadow: 0 0 0 transparent

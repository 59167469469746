.app-icon-list
  --list-gap: var(--spacer-2)

  list-style-type: none
  margin: 0
  padding: 0

  display: grid
  gap: var(--list-gap)

  > li
    --bullet-margin: calc(var(--app-icon-size) + var(--spacer-2))

    margin-inline: 0
    padding-left: var(--bullet-margin)
    position: relative

    .app-icon
      position: absolute
      left: 0

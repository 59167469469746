@import ../environment

.account-box
  border-radius: var(--bs-border-radius-2xl)
  background: $white
  box-shadow: $shadow-sm-blue

  max-width: var(--container-max-sm)
  margin-inline: auto

  padding: var(--spacer-4)
  +media-breakpoint-up($tablet-from)
    padding: var(--spacer-5)

@import ../../environment

up-popup
  margin-top: 1rem
  border-radius: var(--border-radius)
  box-shadow: var(--box-shadow)

  // Fix for an Unpoly bug where it assigns `align="right"` to right-aligned popup containers.
  // Unfortunately, the "align" HTML attribute implies `text-align`, and we don't want that one to change.
  text-align: start

  &[size='content']
    width: fit-content
    max-width: calc(100vw - 20px)

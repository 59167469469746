@import ../environment

$horizontal-from: lg

.course-unit-read-hero
  display: grid
  --box-width: 200px

  --navigation-height: #{$size-2xl}
  +media-breakpoint-up($desktop-from)
    --navigation-height: #{$size-3xl}

  grid-template-columns: auto var(--container-content-width) auto
  grid-template-rows: 0 auto auto auto var(--content-margin)
  grid-template-areas: "background-start . ." ". navigation ." ". text . " ". card ." ". . background-end"

  +media-breakpoint-up($horizontal-from)
    --col-3: #{col-width(3)}
    --col-9: #{col-width(9)}

    grid-template-columns: auto var(--col-9) var(--grid-gutter-width) var(--col-3) auto
    grid-template-rows: auto auto auto var(--content-margin) var(--spacer-4)
    grid-template-areas: "background-start navigation navigation navigation ." ". text . card ." ". text . card ." ". . . card background-end" ". . . card ."


.course-unit-read-hero--background-hero
  grid-column: background-start / background-end
  grid-row: background-start / background-end

  > .hero
    height: 100%


.course-unit-read-hero--navigation
  grid-area: navigation

  min-height: var(--navigation-height)

  display: flex
  align-items: center
  flex-wrap: wrap


.course-unit-read-hero--text
  grid-area: text

  text-align: center
  padding-top: var(--spacer-5)
  padding-bottom: var(--spacer-4)

  +media-breakpoint-up($horizontal-from)
    text-align: unset

    +media-breakpoint-up(xxl)
      padding-right: 6rem


.course-unit-read-hero--card
  grid-area: card
  justify-self: center
  align-self: end
  width: var(--box-width)

  +media-breakpoint-up($horizontal-from)
    &::before
      content: ''
      display: block
      padding-top: 100% // square

    position: relative
    > *
      position: absolute
      inset: 0

.container,
.container-fluid
  &.container-max-sm
    max-width: var(--container-max-sm)
  &.container-max-md
    max-width: var(--container-max-md)
  &.container-max-lg
    max-width: var(--container-max-lg)
  &.container-max-xl
    max-width: var(--container-max-xl)

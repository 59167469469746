@import ../environment

$horizontal-from: md
$score-card-width: px-to-rem(215px)

.user-test-result-hero
  display: grid
  grid-template-columns: auto var(--container-content-width) auto
  grid-template-rows: var(--content-margin) auto auto var(--spacer-3) auto var(--spacer-3) auto
  grid-template-areas: "background-start . ." ". navigation ." ". text ." ". . ." ". score-card background-end" ". score-card ."

  margin-bottom: var(--content-margin)

  +media-breakpoint-up($horizontal-from)
    --col-8: #{col-width(8)}
    --col-4: #{col-width(4)}

    grid-template-columns: auto calc(var(--container-content-width) - var(--grid-gutter-width) - $score-card-width) var(--grid-gutter-width) $score-card-width auto
    grid-template-rows: var(--spacer-4) auto auto auto 1fr var(--spacer-4)
    grid-template-areas: "background-start . . . ." ". navigation navigation navigation ." ". text . . ." ". text . . ." ". text . score-card background-end" ". . . score-card ."

.user-test-result-hero--background
  grid-column: background-start / background-end
  grid-row: background-start / background-end

  > .hero
    height: 100%

.user-test-result-hero--text
  grid-area: text
  text-align: center
  align-self: center

  +media-breakpoint-up($horizontal-from)
    text-align: start
    margin-bottom: var(--spacer-5)

    +media-breakpoint-up(xl)
      padding-right: $size-3xl
    +media-breakpoint-up(xxl)
      padding-right: $size-4xl

.user-test-result-hero--score-card
  grid-area: score-card
  padding-top: var(--spacer-3)

  +media-breakpoint-up($horizontal-from)
    align-self: flex-end

.user-test-result-hero--navigation
  grid-area: navigation

  display: flex
  flex-wrap: wrap
  justify-content: space-between

  color: $primary
  font-weight: $font-weight-bold
  margin-bottom: var(--spacer-5)

  +media-breakpoint-up($horizontal-from)
    margin-bottom: var(--spacer-4)

@import ../environment

.test-question-explanation
  --padding: var(--spacer-4)

  padding: var(--padding)
  background-color: $brand-green-50
  border-radius: $notification-border-radius

  +media-breakpoint-down($fullscreen-course-ui-until)
    --padding: var(--container-padding)

    overflow: auto
    +scrollbar-area(var(--padding))
    +styled-scrollbar($size: $size-4xs, $margin: var(--padding))

.test-question-explanation--title
  --app-icon-size: #{px-to-rem(20px)}

  font-weight: $font-weight-bold

.test-question-explanation--text
  margin-top: var(--spacer-2)

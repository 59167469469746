.course-unit-table-of-contents {
  display: flex;
  flex-direction: column;
}

.course-unit-table-of-contents--section {
  border-bottom: 1px solid #F0F0F0;
  padding-block: var(--spacer-3);
}
.course-unit-table-of-contents--section:last-child {
  border-bottom: none;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcmFkZmFocmF1c2JpbGR1bmdfcC9yZWxlYXNlcy8yMDI1MDIwNTEwNDYzNS9hcHAvYXNzZXRzL2Zyb250ZW5kL2NvbXBvbmVudHMiLCJzb3VyY2VzIjpbImNvdXJzZV91bml0X3RhYmxlX29mX2NvbnRlbnRzLnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRTtFQUNBOzs7QUFFRjtFQUNFO0VBQ0E7O0FBRUE7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgLi4vZW52aXJvbm1lbnRcblxuLmNvdXJzZS11bml0LXRhYmxlLW9mLWNvbnRlbnRzXG4gIGRpc3BsYXk6IGZsZXhcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtblxuXG4uY291cnNlLXVuaXQtdGFibGUtb2YtY29udGVudHMtLXNlY3Rpb25cbiAgYm9yZGVyLWJvdHRvbTogMXB4IHNvbGlkICRncmF5LTMwMFxuICBwYWRkaW5nLWJsb2NrOiB2YXIoLS1zcGFjZXItMylcblxuICAmOmxhc3QtY2hpbGRcbiAgICBib3JkZXItYm90dG9tOiBub25lXG4iXX0= */
@import ../environment

.user-test-notification
  display: grid

  grid-template-areas: "actions"
  grid-template-columns: 1fr

  +media-breakpoint-up($fullscreen-test-ui-until)
    grid-template-areas: "message . actions"
    grid-template-columns: auto 1fr auto

.user-test-notification--message
  display: none

  +media-breakpoint-up($fullscreen-test-ui-until)
    display: block
    grid-area: message

.user-test-notification--actions
  grid-area: actions

  display: flex
  gap: var(--spacer-4)
  align-items: center
  justify-content: space-between

  +media-breakpoint-up($fullscreen-test-ui-until)
    justify-content: end

up-modal[size=small] up-modal-box {
  width: var(--container-max-sm);
}
up-modal[size=medium] up-modal-box {
  width: var(--container-max-md);
}
up-modal[size=large] up-modal-box {
  width: var(--container-max-lg);
}
up-modal[size=max-container] up-modal-box {
  max-width: var(--container-max-xxl);
}

up-modal-box {
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

up-progress-bar {
  background-color: var(--bs-primary);
  box-shadow: -3px 0 2px 2px rgba(var(--bs-white-rgb), 80%);
}

up-modal-box {
  padding: var(--spacer-4);
}

up-drawer-box,
up-cover-box,
up-popup {
  padding: calc(var(--grid-gutter-width) / 2);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcmFkZmFocmF1c2JpbGR1bmdfcC9yZWxlYXNlcy8yMDI1MDIwNTEwNDYzNS9hcHAvYXNzZXRzL3NoYXJlZC9jb25maWciLCJzb3VyY2VzIjpbInVucG9seS5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNFO0VBQ0U7O0FBQ0Y7RUFDRTs7QUFDRjtFQUNFOztBQUNGO0VBQ0U7OztBQUVKO0VBQ0U7RUFDQTs7O0FBRUY7RUFDRTtFQUNBOzs7QUFFRjtFQUNFOzs7QUFFRjtBQUFBO0FBQUE7RUFHRSIsInNvdXJjZXNDb250ZW50IjpbInVwLW1vZGFsLWJveFxuICB1cC1tb2RhbFtzaXplPSdzbWFsbCddICZcbiAgICB3aWR0aDogdmFyKC0tY29udGFpbmVyLW1heC1zbSlcbiAgdXAtbW9kYWxbc2l6ZT0nbWVkaXVtJ10gJlxuICAgIHdpZHRoOiB2YXIoLS1jb250YWluZXItbWF4LW1kKVxuICB1cC1tb2RhbFtzaXplPSdsYXJnZSddICZcbiAgICB3aWR0aDogdmFyKC0tY29udGFpbmVyLW1heC1sZylcbiAgdXAtbW9kYWxbc2l6ZT0nbWF4LWNvbnRhaW5lciddICZcbiAgICBtYXgtd2lkdGg6IHZhcigtLWNvbnRhaW5lci1tYXgteHhsKVxuXG51cC1tb2RhbC1ib3hcbiAgYm9yZGVyLXJhZGl1czogdmFyKC0tYm9yZGVyLXJhZGl1cylcbiAgYm94LXNoYWRvdzogdmFyKC0tYm94LXNoYWRvdylcblxudXAtcHJvZ3Jlc3MtYmFyXG4gIGJhY2tncm91bmQtY29sb3I6IHZhcigtLWJzLXByaW1hcnkpXG4gIGJveC1zaGFkb3c6IC0zcHggMCAycHggMnB4IHJnYmEodmFyKC0tYnMtd2hpdGUtcmdiKSwgODAlKVxuXG51cC1tb2RhbC1ib3hcbiAgcGFkZGluZzogdmFyKC0tc3BhY2VyLTQpXG5cbnVwLWRyYXdlci1ib3gsXG51cC1jb3Zlci1ib3gsXG51cC1wb3B1cFxuICBwYWRkaW5nOiBjYWxjKHZhcigtLWdyaWQtZ3V0dGVyLXdpZHRoKSAvIDIpXG4iXX0= */
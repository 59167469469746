@import ../environment

.test-question-layout
  display: flex
  justify-content: center
  gap: var(--spacer-5)

.test-question-layout--image
  width: 50%
  display: flex
  align-items: center

.test-question-layout--text
  width: 50%
  display: flex
  flex-direction: column
  justify-content: center

.test-question-layout--explanation
  margin-top: var(--spacer-4)

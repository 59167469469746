@import environment
@import root
@import ../fonts/lato
@import ../fonts/nunito

body
  min-height: 100vh
  overflow-y: scroll
  overflow-x: hidden
  -webkit-touch-callout: none !important // Prevent popover for link preview or image sharing which breaks drag&drop games.

h2,
.h2
  font-weight: normal

a
  transition: brand-transition(color)

::selection
  background: rgba($brand-blue, 50%)

[up-source]
  // Unpoly animates slide transitions; at the end of a transition, the element
  // is set to a `transform` which has no effect to the slide's position itself.
  // However, it does introduce a new positioning context which breaks positioning
  // of dragged elements in game slides. We fix that by unsetting that transition.
  &[style="transform: translate(0px, 0px);"], // Chrome
  &[style="transform: translate(0px);"] // Firefox
    transform: none !important

@import ../environment

.test-navigation-buttons
  display: flex
  gap: var(--spacer-1)
  @media (min-width: 360px)
    gap: var(--spacer-2)

.test-navigation-buttons--help,
.test-navigation-buttons--read-aloud
  +media-breakpoint-up($fullscreen-test-ui-until)
    display: none

.test-navigation-buttons--controls
  margin-left: auto

  display: flex
  gap: var(--spacer-3)

@import ../shared/root

\:root
  --app-icon-size: #{px-to-rem(24px)}

  --header-nav-padding-y: #{px-to-rem(28px)}
  --header-height: #{px-to-rem(104px)}

  // default styles for nav-links without a surrounding .navbar-nav or similar (e.g. in _signed_out_header)
  --bs-nav-link-font-weight: #{$nav-link-font-weight}
  --bs-nav-link-color: #{$navbar-light-color}
  --bs-nav-link-hover-color: #{$navbar-light-hover-color}

  @each $name, $width in $grid-gutter-widths
    +media-breakpoint-up($name)
      --grid-gutter-width: #{$width}

@import ../environment

.content-code-search-input
  display: flex
  gap: var(--spacer-3)


.content-code-search-input--field
  flex: 1


.content-code-search-input--button
  --icon-container-size: #{$size-xl}

  width: var(--icon-container-size)
  height: var(--icon-container-size)
  border-radius: 100vmax

  display: grid
  place-content: center

  +media-breakpoint-up($horizontal-code-search-hero-from)
    display: block
    width: auto
    padding-inline: var(--spacer-5)

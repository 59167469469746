.animated-icon
  display: inline-block
  vertical-align: middle
  width: var(--animated-icon-size, 100%)

  &:empty
    &::before
      content: ''
      display: block
      padding-top: 100% // relative to container *width* => 100% = square

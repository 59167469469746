.authentication-teaser-links {
  --bs-link-color: #777777;
  --bs-link-hover-color: #121212;
  --app-icon-margin-right: var(--spacer-2);
  display: flex;
  justify-content: center;
}
.authentication-teaser-links > * {
  position: relative;
}
.authentication-teaser-links > * + *::before {
  content: "";
  position: absolute;
  left: 0;
  z-index: -1;
  height: calc(1em * 1.5);
  width: 1px;
  background: #DAE4FC;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcmFkZmFocmF1c2JpbGR1bmdfcC9yZWxlYXNlcy8yMDI1MDIwNTEwNDYzNS9hcHAvYXNzZXRzL2Zyb250ZW5kL2NvbXBvbmVudHMiLCJzb3VyY2VzIjpbImF1dGhlbnRpY2F0aW9uX3RlYXNlcl9saW5rcy5zYXNzIiwiLi4vLi4vc2hhcmVkL2NvbmZpZy9fYnJhbmRfY29sb3JzLnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRTtFQUNBO0VBQ0E7RUFFQTtFQUNBOztBQUVBO0VBQ0U7O0FBR0E7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUVBO0VBQ0E7RUFDQSxZQ0hpQiIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgLi4vZW52aXJvbm1lbnRcblxuLmF1dGhlbnRpY2F0aW9uLXRlYXNlci1saW5rc1xuICAtLWJzLWxpbmstY29sb3I6ICN7JGdyYXktNzAwfVxuICAtLWJzLWxpbmstaG92ZXItY29sb3I6ICN7JGJvZHktY29sb3J9XG4gIC0tYXBwLWljb24tbWFyZ2luLXJpZ2h0OiB2YXIoLS1zcGFjZXItMilcblxuICBkaXNwbGF5OiBmbGV4XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyXG5cbiAgPiAqXG4gICAgcG9zaXRpb246IHJlbGF0aXZlXG5cbiAgPiAqICsgKlxuICAgICY6OmJlZm9yZVxuICAgICAgY29udGVudDogJydcbiAgICAgIHBvc2l0aW9uOiBhYnNvbHV0ZVxuICAgICAgbGVmdDogMFxuICAgICAgei1pbmRleDogLTFcblxuICAgICAgaGVpZ2h0OiBjYWxjKDFlbSAqICN7JGxpbmUtaGVpZ2h0LWJhc2V9KVxuICAgICAgd2lkdGg6IDFweFxuICAgICAgYmFja2dyb3VuZDogJGJyYW5kLWxpZ2h0LWJsdWUtMjAwXG4iLCIkYnJhbmQtZGFyay1ncmVlbjogIzA1OTUxYiAvLyBEVldcblxuLy8gaHR0cHM6Ly93d3cuZmlnbWEuY29tL2ZpbGUvREpETk9KaWF4VGFQdnVOUDh5ajdDbS9SYWRmYWhyYXVzYmlsZHVuZy1VSSUyRlVYLURlc2lnbj9ub2RlLWlkPTgyNyUzQTk4NzFcblxuJGJyYW5kLWdyZWVuOiAjNjVDMTQ1XG4kYnJhbmQtZ3JlZW4tNzU6ICNFQkY3RTlcbiRicmFuZC1ncmVlbi01MDogI0YyRkFGMFxuJGJyYW5kLW9yYW5nZTogI0U3NzYwRVxuJGJyYW5kLW9yYW5nZS01MDogI0ZERjJFMFxuJGJyYW5kLXJlZDogI0RBMDAwMFxuJGJyYW5kLXJlZC01MDogI0ZGRTlFN1xuJGJyYW5kLWJsdWU6ICMzQzkxRTZcbiRicmFuZC1kYXJrLWJsdWU6ICMwNDAxNDdcblxuJGJyYW5kLWRhcmstYmx1ZS0zMDA6ICM2ODY3OTFcbiRicmFuZC1kYXJrLWJsdWUtMTAwOiAjQjRCM0M4XG4kYnJhbmQtZGFyay1ibHVlLTUwOiAjRTZFNkVEXG5cbiRicmFuZC1saWdodC1ibHVlOiAjRjRGN0ZFXG4kYnJhbmQtbGlnaHQtYmx1ZS0yMDA6ICNEQUU0RkNcbiRicmFuZC1saWdodC1ibHVlLTEwMDogI0U4RUVGRFxuXG4kYnJhbmQtb3JhbmdlLWdyYWRpZW50LXN0YXJ0OiAjRkY5RjBGXG4kYnJhbmQtb3JhbmdlLWdyYWRpZW50LWVuZDogI0ZGMzUzNVxuIl19 */
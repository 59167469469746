@import ../environment

$nugget-color: $brand-green-75
$nugget-padding: $size-3xs $size-2xs
$draggable-sources-from: lg

%sources
  display: flex
  flex-wrap: wrap
  align-content: start
  gap: var(--spacer-2)
  overflow-y: auto

.blanks-text
  --padding: var(--container-padding)
  --scrollbar-area: var(--spacer-2)

  display: grid
  grid-template-areas: "sources-spacer text-body" ". text-body" "sources-label text-body" ". text-body" "sources text-body" ". text-body" "controls text-body"
  grid-template-columns: 25% 1fr
  grid-template-rows: auto 0 auto var(--spacer-3) 1fr var(--spacer-3) auto
  gap: 0 var(--spacer-3)

  height: 100%

  +media-breakpoint-down($draggable-sources-from)
    grid-template-areas: "text-body" "controls"
    grid-template-columns: 1fr
    grid-template-rows: 1fr auto
    gap: var(--spacer-3)

  +media-breakpoint-down($fullscreen-course-ui-until)
    padding: var(--padding)
    padding-right: calc(var(--padding) - var(--scrollbar-area)) // include the scrollbar width in the calculation of the right padding


.blanks-text--heading
  margin-bottom: var(--spacer-4)

.blanks-text--subheading
  color: $text-muted
  margin-bottom: var(--spacer-1)

.blanks-text--sources-spacer
  grid-area: sources-spacer

  +media-breakpoint-down($draggable-sources-from)
    display: none

.blanks-text--sources-label
  grid-area: sources-label
  margin: 0

  +media-breakpoint-down($draggable-sources-from)
    display: none

.blanks-text--sources
  grid-area: sources
  @extend %sources

  +media-breakpoint-down($draggable-sources-from)
    display: none

.blanks-text--sources-modal
  @extend %sources
  --nugget-cursor: pointer

.blanks-text--controls
  grid-area: controls

.blanks-text--text-body
  grid-area: text-body
  white-space: pre-line
  line-height: 2.5
  user-select: none

  overflow: auto
  padding-right: calc(var(--padding) - var(--scrollbar-area))
  +styled-scrollbar($size: $size-4xs, $margin: var(--padding))

.blanks-text--drop-target
  position: relative
  display: inline-block
  line-height: 1.2
  padding: $nugget-padding
  border: 2px solid var(--border-color, $white)
  border-radius: $border-radius-sm
  background-color: var(--background-color, $nugget-color)

  &::before
    // sizer element to make drop target be wide enough
    content: attr(data-sizer-text)
    color: transparent
    display: inline-block

  &:empty
    --border-color: #{$brand-green}

  &:not(:empty)
    --border-color: #{$nugget-color}

  &:not(:empty)::before
    // sizer still consumes width
    height: 0
    overflow: hidden

  &.-incorrect
    --background-color: #{$brand-orange-50}
    --border-color: #{$brand-orange}
    --nugget-background: #{$brand-orange-50}
    --nugget-cursor: #{''}

  &.-correct
    --background-color: #{$brand-green-50}
    --border-color: #{$brand-green}
    --nugget-background: #{$brand-green-50}
    --nugget-cursor: #{''}

  &.-solved
    --background-color: #{transparent}
    --border-color: #{transparent}
    --nugget-background: #{transparent}
    --nugget-cursor: #{''}

.blanks-text--nugget
  display: inline-flex
  align-items: center

  cursor: var(--nugget-cursor, move)
  font-weight: 600
  line-height: 1.2
  padding: $nugget-padding
  user-select: none
  z-index: 1

  background-color: var(--nugget-background, $nugget-color)
  border: 2px solid var(--nugget-border-color, $nugget-color)
  border-radius: $border-radius-sm

  +dragged-nugget-styles

  .blanks-text--drop-target &
    position: absolute
    inset: 0

@keyframes nugget-placed
  0%
    opacity: 0.5
    scale: 1.05
    border-color: $brand-green
    box-shadow: $shadow-dragging

  100%
    scale: 1
    opacity: 1
    border-color: $brand-green-50
    box-shadow: 0 0 0 transparent

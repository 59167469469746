@import ../environment

.score-card
  background-color: $brand-orange-50
  box-shadow: $shadow-sm-blue
  border-radius: $border-radius
  text-align: center
  color: $brand-dark-blue
  font-weight: $font-weight-bold

.score-card--header
  padding: px-to-rem(12px)

.score-card--body
  padding: px-to-rem(24px)
  background-color: $white
  border-radius: $border-radius
  font-size: $lead-font-size

\:root
  --icon-line-height: var(--bs-body-line-height)

.icon
  display: inline-grid
  align-items: center
  gap: 0.35em // more than a space character
  vertical-align: top
  min-height: calc(1em * var(--icon-line-height))

  button &
    pointer-events: none

.icon--image
  grid-row: 1
  grid-column: 1

  fill: currentColor
  width: 1em
  height: 1em

.icon--text
  grid-row: 1
  grid-column: 2

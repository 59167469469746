@import ../environment

.circle-chart
  width: $user-test-chart-size
  height: $user-test-chart-size
  border-radius: 100vmax

  // center text
  display: grid
  place-content: center

  // draw radial progress
  position: relative
  overflow: hidden
  &::before
    content: ''
    position: absolute
    inset: 0
    background: $brand-green conic-gradient($brand-red calc(100% - var(--percent)), transparent calc(100% - var(--percent) + 0.1%))
    --mask: radial-gradient(transparent 55%, #000 56.5%)
    -webkit-mask: var(--mask)
    mask: var(--mask)
    pointer-events: none

.circle-chart--score
  display: inline
  font-size: $lead-font-size

.circle-chart--max
  display: inline
  font-size: $smallest-font-size
  color: $brand-dark-blue-300

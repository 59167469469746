@import ../environment

$horizontal-from: lg

.course-unit-hero
  display: grid

  --vertical-image-max-width: #{px-to-rem(400px)}
  --navigation-height: #{$size-2xl}
  +media-breakpoint-up($desktop-from)
    --navigation-height: #{$size-3xl}

  // Vertical layout
  // (image covers about upper half, hero spans entire element)
  ////////////////////////////////////////////////////////////////////////////
  // | <- left of viewport    | <-    container    -> | right of viewport -> |
  ////////////////////////////////////////////////////////////////////////////
  // | background hero start  |                       |                      |
  // | background image start |                       |                      |
  // |                        | navigation            |                      |
  // |                        | (upper half of image) |                      |
  // |                        | content               | background image end |
  // |                        | content               |                      |
  // |                        |                       | background hero end  |
  --upper-image-height: calc(min(100vw, var(--vertical-image-max-width)) - var(--navigation-height) - var(--grid-gutter-width))

  grid-template-columns: auto var(--container-content-width) auto
  grid-template-rows: 0 0 auto var(--upper-image-height) auto 1fr var(--grid-gutter-width)
  grid-template-areas: "background-hero-start . ." "background-image-start . ." ". navigation ." ". . ." ". content background-image-end" ". content ." ". . background-hero-end"

  +media-breakpoint-up($horizontal-from)
    // Horizontal layout
    // (image covers container area, hero spans entire element)
    // | <- left of viewport   | <- left of container   | <- left of content | (gutter) | right of content -> | right of container -> | right of viewport -> |
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // | background-hero-start | background-image-start | navigation                                          |                       |                      |
    // |                       |                        +-------------------------------+---------------------+                       |                      |
    // |                       |                        | (left third of image)         | content             |                       |                      |
    // |                       |                        |                               +---------------------+                       |                      |
    // |                       |                        |                                                     | background-image-end  | background-hero-end  |
    --uncovered-image-width: #{col-width(4)}
    --content-width: #{col-width(8)}

    grid-template-columns: auto var(--container-padding) var(--uncovered-image-width) var(--grid-gutter-width) var(--content-width) var(--container-padding) auto
    grid-template-rows: auto 1fr #{px-to-rem(90px)}
    grid-template-areas: "background-hero-start background-image-start navigation navigation navigation . ." ". . . . content . ." ". . . . . background-image-end background-hero-end"


.course-unit-hero--background-hero
  grid-column: background-hero-start / background-hero-end
  grid-row: background-hero-start / background-hero-end

  > .hero
    height: 100%


.course-unit-hero--background-image
  grid-area: image

  grid-column: background-image-start / background-image-end
  grid-row: background-image-start / background-image-end

  border-radius: 0 0 $border-radius-lg $border-radius-lg
  overflow: hidden

  > img
    width: 100%

  +media-breakpoint-down($horizontal-from)
    overflow-x: hidden
    max-width: var(--vertical-image-max-width)
    justify-self: center
    > img
      margin-top: -10%
      width: 285%


.course-unit-hero--navigation
  grid-area: navigation

  min-height: var(--navigation-height)

  display: flex
  align-items: center
  flex-wrap: wrap


.course-unit-hero--content
  grid-area: content

  --padding-y: calc(1.5 * var(--spacer-4))
  --padding-x: var(--spacer-4)
  +media-breakpoint-up(xl)
    --padding-y: var(--spacer-5)
    --padding-x: #{col-width(1)}

  padding: var(--padding-y) var(--padding-x)
  background: $white
  box-shadow: $shadow-sm-blue
  border-radius: $border-radius

  text-align: center
  color: $brand-dark-blue-300

  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  gap: var(--grid-gutter-width)

  > *
    margin-block: 0

@import ../environment

.avatar-choices-grid
  display: grid
  grid-template-columns: repeat(3, 1fr)
  gap: var(--spacer-2)

  +media-breakpoint-up($tablet-from)
    grid-template-columns: repeat(4, 1fr)
    gap: var(--spacer-3)

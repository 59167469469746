@import ../environment

.pdf-slide
  --padding: var(--container-padding)

  padding: var(--padding)
  overflow: auto
  max-height: 100%

  +scrollbar-area(var(--padding))
  +styled-scrollbar($size: $size-4xs, $margin: var(--padding))

.pdf-slide--title
  text-align: center

.pdf-slide--assets
  display: flex
  flex-direction: column
  align-items: center
  gap: var(--spacer-3)

  // reset list styles
  list-style-type: none
  padding: 0

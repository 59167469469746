@import ../environment
@import ../config/mixins

.add-to-homescreen
  display: grid
  gap: var(--spacer-4)
  align-items: center
  grid-template-columns: auto 1fr
  grid-template-areas: 'logo title' 'description description' 'controlls controlls'

.add-to-homescreen--logo
  grid-area: logo

.add-to-homescreen--title
  grid-area: title
  margin: 0

.add-to-homescreen--description
  grid-area: description

.add-to-homescreen--controlls
  grid-area: controlls

  display: flex
  gap: var(--spacer-3)

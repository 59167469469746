.lightbox {
  display: block;
  position: fixed;
  z-index: 9999;
  isolation: isolate;
  inset: 0;
  border: 0;
  padding: 0;
  background: transparent;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.25s cubic-bezier(0.61, 1, 0.88, 1);
}
.lightbox[open=true] {
  opacity: 1;
  pointer-events: auto;
  max-width: 100vw;
  max-height: 100vh;
  cursor: zoom-out;
}
.lightbox[open=true]::before {
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  background: black;
  opacity: 0.6;
}

.lightbox--content {
  width: 100vw;
  height: 100vh;
  padding: var(--grid-gutter-width);
}
.lightbox--content > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcmFkZmFocmF1c2JpbGR1bmdfcC9yZWxlYXNlcy8yMDI1MDIwNTEwNDYzNS9hcHAvYXNzZXRzL2Zyb250ZW5kL2NvbXBvbmVudHMiLCJzb3VyY2VzIjpbImxpZ2h0Ym94LnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRTtFQUVBO0VBQ0E7RUFDQTtFQUNBO0VBRUE7RUFDQTtFQUNBO0VBRUE7RUFDQTtFQUVBOztBQUVBO0VBQ0U7RUFDQTtFQUVBO0VBQ0E7RUFDQTs7QUFFQTtFQUNFO0VBQ0E7RUFDQTtFQUVBO0VBQ0E7RUFFQTtFQUNBOzs7QUFHTjtFQUNFO0VBQ0E7RUFDQTs7QUFFQTtFQUNFO0VBQ0E7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgLi4vZW52aXJvbm1lbnRcblxuLmxpZ2h0Ym94XG4gIGRpc3BsYXk6IGJsb2NrXG5cbiAgcG9zaXRpb246IGZpeGVkXG4gIHotaW5kZXg6IDk5OTlcbiAgaXNvbGF0aW9uOiBpc29sYXRlXG4gIGluc2V0OiAwXG5cbiAgYm9yZGVyOiAwXG4gIHBhZGRpbmc6IDBcbiAgYmFja2dyb3VuZDogdHJhbnNwYXJlbnRcblxuICBvcGFjaXR5OiAwXG4gIHBvaW50ZXItZXZlbnRzOiBub25lXG5cbiAgdHJhbnNpdGlvbjogYnJhbmQtdHJhbnNpdGlvbihvcGFjaXR5KVxuXG4gICZbb3Blbj10cnVlXVxuICAgIG9wYWNpdHk6IDFcbiAgICBwb2ludGVyLWV2ZW50czogYXV0b1xuXG4gICAgbWF4LXdpZHRoOiAxMDB2d1xuICAgIG1heC1oZWlnaHQ6IDEwMHZoXG4gICAgY3Vyc29yOiB6b29tLW91dFxuXG4gICAgJjo6YmVmb3JlXG4gICAgICBjb250ZW50OiAnJ1xuICAgICAgd2lkdGg6IDEwMCVcbiAgICAgIGhlaWdodDogMTAwJVxuXG4gICAgICBwb3NpdGlvbjogZml4ZWRcbiAgICAgIHotaW5kZXg6IC0xXG5cbiAgICAgIGJhY2tncm91bmQ6IGJsYWNrXG4gICAgICBvcGFjaXR5OiAuNlxuXG5cbi5saWdodGJveC0tY29udGVudFxuICB3aWR0aDogMTAwdndcbiAgaGVpZ2h0OiAxMDB2aFxuICBwYWRkaW5nOiB2YXIoLS1ncmlkLWd1dHRlci13aWR0aClcblxuICA+IGltZ1xuICAgIGhlaWdodDogMTAwJVxuICAgIHdpZHRoOiAxMDAlXG4gICAgb2JqZWN0LWZpdDogY29udGFpblxuIl19 */
.add-to-homescreen {
  display: grid;
  gap: var(--spacer-4);
  align-items: center;
  grid-template-columns: auto 1fr;
  grid-template-areas: "logo title" "description description" "controlls controlls";
}

.add-to-homescreen--logo {
  grid-area: logo;
}

.add-to-homescreen--title {
  grid-area: title;
  margin: 0;
}

.add-to-homescreen--description {
  grid-area: description;
}

.add-to-homescreen--controlls {
  grid-area: controlls;
  display: flex;
  gap: var(--spacer-3);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcmFkZmFocmF1c2JpbGR1bmdfcC9yZWxlYXNlcy8yMDI1MDIwNTEwNDYzNS9hcHAvYXNzZXRzL2Zyb250ZW5kL2NvbXBvbmVudHMiLCJzb3VyY2VzIjpbImFkZF90b19ob21lc2NyZWVuLnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBR0E7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOzs7QUFFRjtFQUNFOzs7QUFFRjtFQUNFO0VBQ0E7OztBQUVGO0VBQ0U7OztBQUVGO0VBQ0U7RUFFQTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCAuLi9lbnZpcm9ubWVudFxuQGltcG9ydCAuLi9jb25maWcvbWl4aW5zXG5cbi5hZGQtdG8taG9tZXNjcmVlblxuICBkaXNwbGF5OiBncmlkXG4gIGdhcDogdmFyKC0tc3BhY2VyLTQpXG4gIGFsaWduLWl0ZW1zOiBjZW50ZXJcbiAgZ3JpZC10ZW1wbGF0ZS1jb2x1bW5zOiBhdXRvIDFmclxuICBncmlkLXRlbXBsYXRlLWFyZWFzOiAnbG9nbyB0aXRsZScgJ2Rlc2NyaXB0aW9uIGRlc2NyaXB0aW9uJyAnY29udHJvbGxzIGNvbnRyb2xscydcblxuLmFkZC10by1ob21lc2NyZWVuLS1sb2dvXG4gIGdyaWQtYXJlYTogbG9nb1xuXG4uYWRkLXRvLWhvbWVzY3JlZW4tLXRpdGxlXG4gIGdyaWQtYXJlYTogdGl0bGVcbiAgbWFyZ2luOiAwXG5cbi5hZGQtdG8taG9tZXNjcmVlbi0tZGVzY3JpcHRpb25cbiAgZ3JpZC1hcmVhOiBkZXNjcmlwdGlvblxuXG4uYWRkLXRvLWhvbWVzY3JlZW4tLWNvbnRyb2xsc1xuICBncmlkLWFyZWE6IGNvbnRyb2xsc1xuXG4gIGRpc3BsYXk6IGZsZXhcbiAgZ2FwOiB2YXIoLS1zcGFjZXItMylcbiJdfQ== */
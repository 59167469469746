.container.container-max-sm,
.container-fluid.container-max-sm {
  max-width: var(--container-max-sm);
}
.container.container-max-md,
.container-fluid.container-max-md {
  max-width: var(--container-max-md);
}
.container.container-max-lg,
.container-fluid.container-max-lg {
  max-width: var(--container-max-lg);
}
.container.container-max-xl,
.container-fluid.container-max-xl {
  max-width: var(--container-max-xl);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcmFkZmFocmF1c2JpbGR1bmdfcC9yZWxlYXNlcy8yMDI1MDIwNTEwNDYzNS9hcHAvYXNzZXRzL3NoYXJlZC9jb21wb25lbnRzL2Jvb3RzdHJhcF9leHQiLCJzb3VyY2VzIjpbImNvbnRhaW5lci5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVFO0FBQUE7RUFDRTs7QUFDRjtBQUFBO0VBQ0U7O0FBQ0Y7QUFBQTtFQUNFOztBQUNGO0FBQUE7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIi5jb250YWluZXIsXG4uY29udGFpbmVyLWZsdWlkXG4gICYuY29udGFpbmVyLW1heC1zbVxuICAgIG1heC13aWR0aDogdmFyKC0tY29udGFpbmVyLW1heC1zbSlcbiAgJi5jb250YWluZXItbWF4LW1kXG4gICAgbWF4LXdpZHRoOiB2YXIoLS1jb250YWluZXItbWF4LW1kKVxuICAmLmNvbnRhaW5lci1tYXgtbGdcbiAgICBtYXgtd2lkdGg6IHZhcigtLWNvbnRhaW5lci1tYXgtbGcpXG4gICYuY29udGFpbmVyLW1heC14bFxuICAgIG1heC13aWR0aDogdmFyKC0tY29udGFpbmVyLW1heC14bClcbiJdfQ== */
@import ../environment

=buttons-as-row
  justify-content: center
  flex-direction: row
  flex-wrap: wrap

.question-choices
  display: flex
  flex-direction: column
  justify-content: center
  gap: var(--spacer-2)

  +media-breakpoint-up(md)
    justify-content: center

  &.-buttons
    &.-below-asset
      +buttons-as-row

    +media-breakpoint-down($horizontal-question-layout-from)
      +buttons-as-row

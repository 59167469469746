@import ../environment

$horizontal-from: lg

.text-slide
  display: grid
  grid-template-columns: 1fr
  grid-template-rows: auto 1fr
  grid-template-areas: 'asset' 'text'
  column-gap: var(--spacer-3)

  // Set the height of text-slide to a fixed width, so that text-slide--text can get a scroller on overflow
  height: 100%

  +media-breakpoint-up($horizontal-from)
    grid-template-columns: 1fr 1fr
    grid-template-areas: 'asset text'

  &.-no-asset
    grid-template-columns: 1fr
    grid-template-areas: 'text'
    justify-items: center

.text-slide--text
  grid-area: text

  +media-breakpoint-down($horizontal-from)
    padding-top: var(--spacer-3)

  +media-breakpoint-down($fullscreen-course-ui-until)
    padding: var(--container-padding)
    overflow: auto

    +scrollbar-area(var(--container-padding))
    +styled-scrollbar($size: $size-4xs, $margin: var(--container-padding))

  &.-reading-mode
    justify-self: flex-start

.text-slide--asset
  grid-area: asset

  & .image
    border-radius: 0

    +media-breakpoint-down($horizontal-from)
      max-height: $max-asset-height-on-mobile-slides
      width: auto

      // center the image
      display: block
      margin: auto

@import ../environment

$max-scorecard-width: px-to-rem(350px)

.user-test-result-score-card
  max-width: $max-scorecard-width
  margin-inline: auto
  background-color: $white
  box-shadow: $shadow-sm-blue
  border-radius: $border-radius
  padding: var(--spacer-4)

  color: $brand-dark-blue
  text-align: center
  font-weight: $font-weight-bold

  display: flex
  flex-direction: column
  align-items: center

.user-test-result-score-card--text
  font-size: $lead-font-size
  margin-top: var(--spacer-3)

@import ../environment

.hero
  width: 100%
  --padding-top: var(--content-margin)
  --padding-bottom: var(--content-margin)

  --border-radius: #{$border-radius}
  +media-breakpoint-up($tablet-from)
    --border-radius: #{$border-radius-lg}

  --bs-link-color: #{$brand-dark-blue}
  --bs-link-hover-color: #{$brand-dark-blue-300}

  background-color: $brand-light-blue
  padding-top: var(--padding-top)
  padding-bottom: var(--padding-bottom)
  border-radius: 0 0 var(--border-radius) var(--border-radius)
  margin: 0 0 var(--content-margin)
  &.-bottom
    --padding-top: #{$size-2xl}
    --padding-bottom: calc(var(--padding-top) + var(--border-radius))

    background-color: $brand-light-blue
    border-radius: var(--border-radius) var(--border-radius) 0 0
    margin: var(--content-margin) 0 0

  &.-hills
    background-color: $brand-light-blue-100
    background-image: url(/var/www/radfahrausbildung_p/releases/20250205104635/app/assets/frontend/components/../../images/dashboard/start-intro-bg.svg)
    background-repeat: no-repeat

    background-position: right top
    background-size: 50rem auto

    +media-breakpoint-up($tablet-from)
      background-size: 65rem auto

    +media-breakpoint-up($horizontal-dashboard-hero-from)
      background-position: center 85%
      background-size: cover

  &.-clouds
    z-index: 0
    position: relative
    overflow: hidden

    &::before,
    &::after
      --image-width: calc(1px * var(--image-width-unitless))
      --animation-duration: calc(1s * (var(--image-width-unitless) / var(--px-per-second)))

      content: ''
      display: block
      position: absolute
      top: 0
      left: 0

      background-image: var(--image-url)
      background-size: var(--image-width) auto
      background-repeat: repeat-x
      background-position: var(--offset-x) var(--offset-y)

      width: calc(100% + var(--image-width))
      height: 100%

      animation-duration: var(--animation-duration)
      animation-timing-function: linear
      animation-iteration-count: infinite
      animation-name: image-scroll-x

      will-change: transform

    &::before
      z-index: -1

      --image-url: url(/var/www/radfahrausbildung_p/releases/20250205104635/app/assets/frontend/components/../../images/dashboard/clouds-level-1.svg)
      --image-width-unitless: 1667
      --px-per-second: 11.71

      --offset-x: 220px
      --offset-y: 24px

    &::after
      z-index: -2

      --image-url: url(../../images/dashboard/clouds-level-2.svg)
      --image-width-unitless: 1821
      --px-per-second: 10.51

      --offset-x: 500px
      --offset-y: 16px

    @keyframes image-scroll-x
      0%
        transform: translateX(0)

      100%
        transform: translateX(calc(-1 * var(--image-width)))

@import ../environment

.lightbox
  display: block

  position: fixed
  z-index: 9999
  isolation: isolate
  inset: 0

  border: 0
  padding: 0
  background: transparent

  opacity: 0
  pointer-events: none

  transition: brand-transition(opacity)

  &[open=true]
    opacity: 1
    pointer-events: auto

    max-width: 100vw
    max-height: 100vh
    cursor: zoom-out

    &::before
      content: ''
      width: 100%
      height: 100%

      position: fixed
      z-index: -1

      background: black
      opacity: .6


.lightbox--content
  width: 100vw
  height: 100vh
  padding: var(--grid-gutter-width)

  > img
    height: 100%
    width: 100%
    object-fit: contain

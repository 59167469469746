.confetti-avatar {
  position: relative;
}

.confetti-avatar--animation {
  position: absolute;
  top: -33%;
  left: -10%;
  right: -10%;
}

.confetti-avatar--avatar {
  isolation: isolate;
  display: grid;
  justify-items: center;
  padding-top: 33%;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcmFkZmFocmF1c2JpbGR1bmdfcC9yZWxlYXNlcy8yMDI1MDIwNTEwNDYzNS9hcHAvYXNzZXRzL2Zyb250ZW5kL2NvbXBvbmVudHMiLCJzb3VyY2VzIjpbImNvbmZldHRpX2F2YXRhci5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUVBO0VBQ0U7OztBQUVGO0VBQ0U7RUFDQTtFQUNBO0VBQ0E7OztBQUVGO0VBQ0U7RUFFQTtFQUNBO0VBRUEiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0IC4uL2Vudmlyb25tZW50XG5cbi5jb25mZXR0aS1hdmF0YXJcbiAgcG9zaXRpb246IHJlbGF0aXZlXG5cbi5jb25mZXR0aS1hdmF0YXItLWFuaW1hdGlvblxuICBwb3NpdGlvbjogYWJzb2x1dGVcbiAgdG9wOiAtMzMlXG4gIGxlZnQ6IC0xMCVcbiAgcmlnaHQ6IC0xMCVcblxuLmNvbmZldHRpLWF2YXRhci0tYXZhdGFyXG4gIGlzb2xhdGlvbjogaXNvbGF0ZVxuXG4gIGRpc3BsYXk6IGdyaWRcbiAganVzdGlmeS1pdGVtczogY2VudGVyXG5cbiAgcGFkZGluZy10b3A6IDMzJVxuIl19 */
@import ../environment

.translated-text
  display: flex
  flex-direction: column
  width: 100%

  > *
    flex-grow: 1

  &.-smaller-with-translation:has(.-has-translations)
    font-size: max(0.75em, .875rem)

  &.-horizontal
    display: inline flex
    column-gap: spacer(3)
    flex-direction: row
    flex-wrap: wrap
    width: max-content
    max-width: 100%

  &.-inline
    display: inline flow
    width: auto

    > *
      display: inline

  label:has(&)
    width: 100%

  &.-with-separator:has(.-has-translations) .translated-text--original::after
    display: inline flow-root
    content: '·'
    text-align: center
    width: spacer(3)


.translated-text--translation
  font-weight: normal
  filter: opacity(60%)

  &.-keep-color
    filter: none

  &.-smaller
    font-size: max(0.75em, .875rem)

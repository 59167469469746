@import ../environment

.video-slide
  display: grid
  grid-template-columns: 1fr
  grid-template-areas: 'title' 'text' 'play-button'
  gap: var(--spacer-3)

.video-slide--title
  grid-area: title

.video-slide--text
  grid-area: text

.video-slide--play-button
  grid-area: play-button

@import ../environment

.confetti-avatar
  position: relative

.confetti-avatar--animation
  position: absolute
  top: -33%
  left: -10%
  right: -10%

.confetti-avatar--avatar
  isolation: isolate

  display: grid
  justify-items: center

  padding-top: 33%

@import ../environment

.counter-circle
  background-color: $brand-light-blue-100
  width: $user-test-chart-size
  height: $user-test-chart-size
  line-height: $user-test-chart-size
  text-align: center
  font-size: calc($user-test-chart-size / 2)
  font-weight: 600
  border-radius: 100vmax


.counter-circle--number
  background: linear-gradient(180deg, $brand-orange-gradient-start 0%, $brand-orange-gradient-end 84.09%)
  -webkit-background-clip: text
  background-clip: text
  color: transparent

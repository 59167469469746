@import ../environment

.footer-links
  --bs-link-color: #{$gray-700}
  --bs-link-hover-color: #{$body-color}

  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: center
  gap: 2 * $size-2xs

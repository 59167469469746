@import ./functions

$tablet-from: sm
$desktop-from: xl

$horizontal-dashboard-hero-from: lg
$horizontal-code-search-hero-from: lg
$course-unit-scroller-button-size: px-to-rem(48px)
$course-unit-scroller-button-gap: px-to-rem(10px)

$fullscreen-test-ui-until: md
$fullscreen-course-ui-until: md

$horizontal-question-layout-from: lg

$border-radius-xs: px-to-rem(4px)
$border-radius-sm: px-to-rem(10px)
$border-radius: px-to-rem(20px)
$border-radius-md: px-to-rem(30px)
$border-radius-lg: px-to-rem(50px)

$slide-border-radius: px-to-rem(30px)

$container-padding-mobile: px-to-rem(20px)
$container-padding-tablet: px-to-rem(40px)
$container-content-max-width: px-to-rem(1400px)
$container-max-width-incl-padding: $container-content-max-width + $container-padding-tablet * 2

$mobile-navigation-height: px-to-rem(77px)
$tablet-navigation-height: px-to-rem(97px)
$desktop-navigation-height: px-to-rem(104px)

$size-4xs: px-to-rem(4px)
$size-3xs: px-to-rem(8px)
$size-2xs: px-to-rem(12px)
$size-xs: px-to-rem(20px)
$size-s: px-to-rem(28px)
$size-m: px-to-rem(36px)
$size-l: px-to-rem(44px)
$size-xl: px-to-rem(48px)
$size-2xl: px-to-rem(60px)
$size-3xl: px-to-rem(76px)
$size-4xl: px-to-rem(100px)
$size-5xl: px-to-rem(124px)

$brand-transition-timing-function: cubic-bezier(0.61, 1, 0.88, 1)

////////////////////////////////////////////////////////////////////////////////
// Bootstrap configuration
// Most of the Sass variables below apply to multiple elements, and/or are used
// as a base for other variables or styles (like hover effects).
////////////////////////////////////////////////////////////////////////////////

$enable-shadows: false
$enable-gradients: false
$enable-container-classes: false // we roll our own

$min-contrast-ratio: 2.2 // bad choice for accessibility, but follows design

$grid-gutter-from-xs: px-to-rem(20px)
$grid-gutter-from-md: px-to-rem(24px)

$grid-gutter-width: var(--grid-gutter-width) // set in frontend/_root.sass based on breakpoints:
$grid-gutter-widths: (xs: $grid-gutter-from-xs, sm: $grid-gutter-from-xs, md: $grid-gutter-from-md, lg: $grid-gutter-from-md, xl: $grid-gutter-from-md, xxl: $grid-gutter-from-md)

$grid-breakpoints: (xs: 0, sm: px-to-rem(576px), md: px-to-rem(768px), lg: px-to-rem(992px), xl: px-to-rem(1220px), xxl: $container-max-width-incl-padding)
$container-max-widths: (xs: 100%, sm: 100%, md: 100%, lg: 100%, xl: 100%, xxl: $container-content-max-width)


////////////////////////////////////////
// Colors
////////////////////////////////////////

$white:    #fff
$black:    #000

@import ../../shared/config/brand_colors

$gray-200: #FAFAFA
$gray-300: #F0F0F0
$gray-400: #E2E2E2
$gray-500: #C2C2C2
$gray-600: #989898
$gray-700: #777777

$gray-900: #121212 // also $body-color

$light: $brand-light-blue
$dark: #121212

$primary: $brand-green
$secondary: $brand-green-50

$success: $brand-green
$danger: $brand-red
$warning: $brand-orange
$info: $brand-blue

$code-color: $brand-dark-blue-300

$hr-opacity: .1

$slide-overview-default: #277DA1
$slide-overview-question: #F9844A

$demo-highlight-color: $brand-dark-blue-300
$course-unit-ribbon-colors: (draft: $danger, archived: $dark, demo: $info)


////////////////////////////////////////
// Shadows
// from https://www.figma.com/file/DJDNOJiaxTaPvuNP8yj7Cm/Radfahrausbildung-UI%2FUX-Design?node-id=828%3A10003
////////////////////////////////////////

$shadow-sm: 0px -2px 55px rgba(192, 192, 192, 0.18), 0px -0.446726px 12.285px rgba(192, 192, 192, 0.1194), 0px -0.133002px 3.65756px rgba(192, 192, 192, 0.0906)
$shadow-lg: 0px 17px 80px rgba(0, 0, 0, 0.04), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0287542), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0238443), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.02), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0161557), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0112458)
$shadow-hard: 0px 0px 4px rgba(0, 0, 0, 0.35)
$shadow-sm-blue: 0px -2px 55px rgba(143, 190, 218, 0.15), 0px -0.446726px 12.285px rgba(143, 190, 218, 0.0894161), 0px -0.133002px 3.65756px rgba(143, 190, 218, 0.0605839)

$shadow-sm-blue-smaller-area: 0px -2px 15px rgba(143, 190, 218, 0.1), 0px -0.446726px 12.285px rgba(143, 190, 218, 0.0894161), 0px -0.133002px 3.65756px rgba(143, 190, 218, 0.0605839)
$shadow-sm-blue-smaller-area-hover: 0px -1px 3px 1px rgba(143, 190, 218, 0.1), 0px -0.446726px 12.285px rgba(143, 190, 218, 0.0894161), 0px -0.133002px 3.65756px rgba(143, 190, 218, 0.0605839)

$shadow-dragging: 0px 0px 15px rgba(101, 193, 69, 0.5)


////////////////////////////////////////
// Typography
////////////////////////////////////////

// Font sizes were given in px in design; we want to use rem wherever possible to respect users' system settings
$h1-font-size: px-to-rem(30px)
$h2-font-size: px-to-rem(26px)
$h3-font-size: px-to-rem(18px)
$h4-font-size: 1rem
$h5-font-size: 1rem
$h6-font-size: 1rem

$lead-font-size: px-to-rem(18px)
$small-font-size: px-to-rem(14px)
$smaller-font-size: px-to-rem(12px)
$smallest-font-size: px-to-rem(10px)

$rfs-breakpoint: 576px

$font-family-sans-serif: Lato, system-ui, -apple-system, sans-serif
$headings-font-family: Nunito, system-ui, -apple-system, sans-serif
$headings-font-weight: 700
$headings-margin-bottom: 1.25rem
$headings-color: $brand-dark-blue

$font-weight-light: 400
$font-weight-normal: 400
$font-weight-semibold: 700
$font-weight-bold: 700

$font-weight-lighter: 400
$font-weight-bolder: 700

$display-font-weight: 700


////////////////////////////////////////////////////////////////////////////////
// Header / Navigation
////////////////////////////////////////////////////////////////////////////////

$navbar-light-color: $brand-dark-blue
$navbar-light-hover-color: $brand-dark-blue-300
$navbar-light-active-color: $primary

$navbar-nav-link-padding-x: 1.2rem

$nav-link-font-weight: 700


////////////////////////////////////////////////////////////////////////////////
// (Form) Controls
////////////////////////////////////////////////////////////////////////////////

$input-btn-border-width: 1px

// Inputs have more vertical padding than buttons. This is as per design.
// When placing buttons next to inputs, they appear squished (not tall enough),
// but in any designs we know, buttons are always placed on their own line.
$input-btn-padding-y: px-to-rem(14px - $input-btn-border-width)
$input-btn-padding-x: 1rem

$form-label-font-weight: $font-weight-bold

$form-group-spacer: 2.25rem

$input-border-radius: px-to-rem(4px)
$input-border-radius-sm: px-to-rem(2px)
$input-border-radius-lg: px-to-rem(8px)

$checkbox-border-width: px-to-rem(2px)

////////////////////////////////////////////////////////////////////////////////
// Misc components
////////////////////////////////////////////////////////////////////////////////

$progress-bg: $brand-light-blue-200

$card-group-margin: calc(var(--grid-gutter-width) / 2)


////////////////////////////////////////////////////////////////////////////////
// Buttons (inherit some form control styles)
////////////////////////////////////////////////////////////////////////////////

$btn-font-weight: $font-weight-bold
$btn-border-width: 1px

$btn-padding-x: 1rem
$btn-padding-y: px-to-rem(10px - $btn-border-width)
$input-btn-line-height: 1.25

$btn-padding-y-lg: px-to-rem(12px - $btn-border-width)
$btn-padding-x-lg: 2rem
$btn-font-size-lg: 1rem

$btn-border-radius:    100vmax
$btn-border-radius-sm: 100vmax
$btn-border-radius-lg: 100vmax

$btn-transition: brand-transition(color, background-color, border-color, box-shadow)

@import ../../shared/config/bootstrap_variables_loader

////////////////////////////////////////////////////////////////////////////////
// Custom variable declarations after Bootstrap variables have been initialized.
// Note that none of these overrides a Bootstrap variable.
////////////////////////////////////////////////////////////////////////////////

$user-test-chart-size: px-to-rem(66px)
$notification-border-radius: px-to-rem(10px)

$mobile-slide-notification-height: 38px // 1rem * notification line height + 2 * vertical padding (spacer 2)
$mobile-course-ui-navigation-height: 72px // 1rem * button line height + 2 * button padding (incl border) + 2 * navigation padding
$mobile-course-ui-content-min-height: 180px // enough space for content and an explanation
$max-asset-height-on-mobile-slides: calc(100vh - #{$mobile-slide-notification-height + $mobile-course-ui-navigation-height + $mobile-course-ui-content-min-height})

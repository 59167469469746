@import ../environment

.qr-scanner
  --size: var(--qr-scanner-size, 336px)

  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  gap: $size-xs
  padding: var(--spacer-3)

  border-radius: $border-radius-md
  border: $size-3xs solid $brand-dark-blue-50

  width: var(--size)
  min-height: var(--size)

  isolation: isolate
  position: relative
  overflow: hidden

  --title-color: #{$brand-green}
  --text-color: #{$brand-dark-blue-300}
  background-color: $brand-green-50

  &.-permission-denied
    --title-color: #{$brand-orange}
    background-color: $brand-orange-50

  &.-no-camera
    --title-color: #{$body-color}
    background-color: $white

  &.-busy
    cursor: wait

  &.-active
    max-height: var(--size)
    padding: 0
    > [class*="qr-scanner--"]:not(.qr-scanner--video)
      display: none

.qr-scanner--video
  position: absolute
  top: 0
  left: 0
  object-fit: cover

  // The qr-scanner library uses opacity/width/height to hide/show the video-stream
  opacity: 100%
  width: 100%
  height: 100%

  z-index: -1

.qr-scanner--text
  color: var(--text-color)
  text-align: center
  text-wrap: balance
  font-size: $small-font-size

.qr-scanner--title
  color: var(--title-color)
  text-align: center
  text-wrap: balance

  font-family: $headings-font-family
  font-weight: $headings-font-weight
  font-size: $h3-font-size
  line-height: $h3-font-size + $size-4xs
  margin: 0

@import ../environment

.user-test-ui
  display: grid
  grid-template-columns: 1fr
  grid-template-rows: 1fr auto
  grid-template-areas: "slide-container" "navigation"
  gap: var(--spacer-3)

  +media-breakpoint-down($fullscreen-test-ui-until)
    +full-screen-ui

  &::before
    +ui-slide-tensioner

.user-test-ui--slide-container
  grid-area: slide-container

  background-color: $white
  box-shadow: $shadow-sm-blue

  position: relative
  overflow: hidden

  display: grid // make child as tall as element

  +media-breakpoint-up($fullscreen-test-ui-until)
    border-radius: $slide-border-radius

.user-test-ui--navigation
  grid-area: navigation

  +media-breakpoint-down($fullscreen-test-ui-until)
    background-color: $light
    padding: var(--spacer-3) var(--container-padding)

@import ../environment

$horizontal-from: $horizontal-dashboard-hero-from

.user-test-hero
  display: grid
  grid-template-columns: auto var(--container-content-width) auto
  grid-template-rows: var(--content-margin) auto var(--spacer-3) auto var(--spacer-3) auto
  grid-template-areas: "background-start . ." ". text ." ". . ." ". image ." ". score-card background-end" ". score-card ."

  margin-bottom: var(--content-margin)

  +media-breakpoint-up($horizontal-from)
    --col-6: #{col-width(6)}

    grid-template-columns: auto var(--col-6) var(--grid-gutter-width) var(--col-6) auto
    grid-template-rows: var(--content-margin) auto 1fr var(--content-margin)
    grid-template-areas: "background-start text . . ." ". text . image ." ". text . score-card background-end" ". . . score-card ."

    margin-bottom: var(--spacer-3)

.user-test-hero--background-hero
  grid-column: background-start / background-end
  grid-row: background-start / background-end

  > .hero
    height: 100%

.user-test-hero--text
  grid-area: text

  text-align: center
  align-self: center

  +media-breakpoint-up($horizontal-from)
    text-align: unset

    +media-breakpoint-up(xl)
      padding-right: 6rem

    +media-breakpoint-up(xxl)
      padding-right: 12rem


.user-test-hero--image
  grid-area: image

  width: 100%
  max-width: px-to-rem(400px)
  margin-inline: auto
  > img
    width: 100%
    margin-bottom: -22%

  +media-breakpoint-up(lg)
    margin-top: 0

.user-test-hero--score-card
  grid-area: score-card

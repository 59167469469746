@import ../environment

.user-test-result-footer
  margin-block: #{px-to-rem(60px)}

  display: flex
  align-items: center
  justify-content: center
  gap: var(--spacer-5)

  +media-breakpoint-down(lg)
    flex-direction: column
    gap: var(--spacer-4)

.user-test-result-footer--text
  color: $text-muted
  +font-size($h2-font-size)
  text-align: center

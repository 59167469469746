@import ../environment

.grid-game-layout
  display: grid
  justify-content: center
  justify-items: center
  gap: var(--spacer-3)

.grid-game-layout--text
  color: $headings-color
  font-size: question-font-size()
  font-weight: $headings-font-weight
  font-family: $headings-font-family

.grid-game-layout--controls
  padding-top: var(--spacer-4)

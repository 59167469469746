@import ../environment

$horizontal-from: lg

.dashboard-tests-section
  background-color: $secondary
  border-radius: $border-radius

  display: grid
  grid-template-columns: 1fr
  grid-template-rows: auto auto auto
  grid-template-areas: "text" "actions" "image"
  gap: spacer(3)
  padding: spacer(5) spacer(4)

  +media-breakpoint-up($horizontal-from)
    grid-template-columns: max-content auto
    grid-template-rows: auto auto
    grid-template-areas: "text image" "actions image"

    padding: 0
    padding-left: spacer(5)
    padding-right: spacer(3)

.dashboard-tests-section--text
  grid-area: text
  text-align: center

  +media-breakpoint-up($horizontal-from)
    align-self: end
    padding-top: spacer(3)
    text-align: unset

.dashboard-tests-section--actions
  grid-area: actions

  display: flex
  gap: spacer(2) spacer(4)
  flex-direction: column
  align-items: center

  +media-breakpoint-up($horizontal-from)
    align-self: start
    padding-bottom: spacer(3)

    flex-direction: row
    flex-wrap: wrap
    white-space: nowrap

.dashboard-tests-section--image
  grid-area: image

  width: 100%
  max-width: px-to-rem(320px)
  margin-inline: auto
  overflow: hidden
  > img
    width: 100%

    +media-breakpoint-up($horizontal-from)
      margin-bottom: -10%

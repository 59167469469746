.btn-circle {
  --size: 2.5rem;
  --bs-btn-border-radius: 100vmax;
  --bs-btn-padding-x: 0;
  --bs-btn-padding-y: 0;
  width: var(--size);
  height: var(--size);
  overflow: hidden;
  display: inline-grid;
  place-content: center;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcmFkZmFocmF1c2JpbGR1bmdfcC9yZWxlYXNlcy8yMDI1MDIwNTEwNDYzNS9hcHAvYXNzZXRzL2Zyb250ZW5kL2NvbXBvbmVudHMiLCJzb3VyY2VzIjpbImJ0bl9jaXJjbGUuc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFO0VBRUE7RUFDQTtFQUNBO0VBRUE7RUFDQTtFQUNBO0VBRUE7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgLi4vZW52aXJvbm1lbnRcblxuLmJ0bi1jaXJjbGVcbiAgLS1zaXplOiAjezIgKiAkc2l6ZS14c31cblxuICAtLWJzLWJ0bi1ib3JkZXItcmFkaXVzOiAxMDB2bWF4XG4gIC0tYnMtYnRuLXBhZGRpbmcteDogMFxuICAtLWJzLWJ0bi1wYWRkaW5nLXk6IDBcblxuICB3aWR0aDogdmFyKC0tc2l6ZSlcbiAgaGVpZ2h0OiB2YXIoLS1zaXplKVxuICBvdmVyZmxvdzogaGlkZGVuXG5cbiAgZGlzcGxheTogaW5saW5lLWdyaWRcbiAgcGxhY2UtY29udGVudDogY2VudGVyXG4iXX0= */
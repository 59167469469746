@import ../environment

$horizontal-from: md
$avatar-box-width: px-to-rem(220px)

.course-completion-message
  display: grid
  grid-template-columns: 1fr
  grid-template-rows: auto auto
  grid-template-areas: "avatar" "text"
  justify-items: center
  gap: var(--grid-gutter-width)

  overflow-y: hidden

  +media-breakpoint-up($horizontal-from)
    grid-template-columns: 1fr $avatar-box-width
    grid-template-rows: auto
    grid-template-areas: "text avatar"
    justify-items: start
    gap: 0

.course-completion-message--text
  grid-area: text
  align-self: center

  text-align: center
  padding-bottom: $size-s
  +media-breakpoint-up($horizontal-from)
    text-align: unset
    padding-top: $size-s


.course-completion-message--avatar
  grid-area: avatar
  justify-self: center

  width: 100%
  max-width: $avatar-box-width
  overflow: hidden

  +media-breakpoint-up($horizontal-from)
    padding-bottom: $size-m

  --avatar-size: #{px-to-rem(80px)}
  +media-breakpoint-up($desktop-from)
    --avatar-size: #{px-to-rem(100px)}

.environment-banner {
  --height: var(--banner-height, var(--spacer-4));
  height: var(--height);
  line-height: var(--height);
  text-align: center;
  font-weight: bold;
  font-size: 0.875rem;
  background-image: linear-gradient(45deg, rgba(255, 255, 0, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 5rem 5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.environment-banner::before {
  content: attr(data-environment);
}
.environment-banner[data-environment=development] {
  background-color: var(--bs-dark);
  color: var(--bs-white);
}
.environment-banner[data-environment=staging] {
  background-color: var(--bs-yellow);
}
.environment-banner[data-environment=production] {
  background-color: var(--bs-danger);
  color: var(--bs-white);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcmFkZmFocmF1c2JpbGR1bmdfcC9yZWxlYXNlcy8yMDI1MDIwNTEwNDYzNS9hcHAvYXNzZXRzL3NoYXJlZC9jb21wb25lbnRzIiwic291cmNlcyI6WyJlbnZpcm9ubWVudF9iYW5uZXIuc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7RUFDQTtFQUVBO0VBQ0E7RUFDQTtFQUVBO0VBQ0E7RUFDQTtFQUVBO0VBQ0E7O0FBRUE7RUFDRTs7QUFFRjtFQUNFO0VBQ0E7O0FBRUY7RUFDRTs7QUFFRjtFQUNFO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIuZW52aXJvbm1lbnQtYmFubmVyXG4gIC0taGVpZ2h0OiB2YXIoLS1iYW5uZXItaGVpZ2h0LCB2YXIoLS1zcGFjZXItNCkpXG4gIGhlaWdodDogdmFyKC0taGVpZ2h0KVxuICBsaW5lLWhlaWdodDogdmFyKC0taGVpZ2h0KVxuXG4gIHRleHQtYWxpZ246IGNlbnRlclxuICBmb250LXdlaWdodDogYm9sZFxuICBmb250LXNpemU6IC44NzVyZW1cblxuICBiYWNrZ3JvdW5kLWltYWdlOiBsaW5lYXItZ3JhZGllbnQoNDVkZWcscmdiYSgyNTUsMjU1LDAsLjE1KSAyNSUsdHJhbnNwYXJlbnQgMjUlLHRyYW5zcGFyZW50IDUwJSxyZ2JhKDI1NSwyNTUsMjU1LC4xNSkgNTAlLHJnYmEoMjU1LDI1NSwyNTUsLjE1KSA3NSUsdHJhbnNwYXJlbnQgNzUlLHRyYW5zcGFyZW50KVxuICBiYWNrZ3JvdW5kLXNpemU6IDVyZW0gNXJlbVxuICBib3JkZXItYm90dG9tOiAxcHggc29saWQgcmdiYSgwLCAwLCAwLCAuMTUpXG5cbiAgbGV0dGVyLXNwYWNpbmc6IC41cHhcbiAgdGV4dC10cmFuc2Zvcm06IHVwcGVyY2FzZVxuXG4gICY6OmJlZm9yZVxuICAgIGNvbnRlbnQ6IGF0dHIoZGF0YS1lbnZpcm9ubWVudClcblxuICAmW2RhdGEtZW52aXJvbm1lbnQ9XCJkZXZlbG9wbWVudFwiXVxuICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLWJzLWRhcmspXG4gICAgY29sb3I6IHZhcigtLWJzLXdoaXRlKVxuXG4gICZbZGF0YS1lbnZpcm9ubWVudD1cInN0YWdpbmdcIl1cbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiB2YXIoLS1icy15ZWxsb3cpXG5cbiAgJltkYXRhLWVudmlyb25tZW50PVwicHJvZHVjdGlvblwiXVxuICAgIGJhY2tncm91bmQtY29sb3I6IHZhcigtLWJzLWRhbmdlcilcbiAgICBjb2xvcjogdmFyKC0tYnMtd2hpdGUpXG4iXX0= */
@import ../environment
@import ../config/authentication_box

$horizontal-from: lg

=when-camera-available
  .layout:not(.-desktop-device) &,
  .layout.-desktop-device.-has-camera &
    @content

.authentication-form
  +authentication-box($large-padding-x: $size-m)

  display: grid
  grid-template-areas: "heading" "scanner" "fields" "hint" "button"
  grid-template-columns: 1fr

  +media-breakpoint-up($horizontal-from)
    +when-camera-available
      --grid-gutter-width: #{$size-xl}
      --scanner-width: #{col-width(6, calc(var(--container-content-width) - 2 * var(--authentication-box-padding-x)))}

      grid-template-areas: "heading heading" "fields scanner" "hint scanner" "button scanner" ". scanner"
      grid-template-columns: 1fr var(--scanner-width)
      grid-template-rows: auto auto auto auto 1fr
      gap: 0 var(--grid-gutter-width)


.authentication-form--heading
  +authentication-box--heading($margin: $size-m)

  grid-area: heading

.authentication-form--scanner
  grid-area: scanner
  justify-self: center

  display: none
  +when-camera-available
    display: block

  margin: 0 0 $size-m
  +media-breakpoint-up($horizontal-from)
    margin: 0
    --qr-scanner-size: var(--scanner-width)

  +media-breakpoint-down($horizontal-from)
    --qr-scanner-size: min(300px, calc(100vw - 2 * var(--container-padding) - 2 * var(--authentication-box-padding-x)))

.authentication-form--fields
  grid-area: fields

  +media-breakpoint-up($horizontal-from)
    +when-camera-available
      padding-top: $size-s

.authentication-form--hint
  grid-area: hint

  color: $warning
  font-size: $small-font-size

  min-height: $small-font-size * $line-height-base
  margin: 0 0 $size-2xs

.authentication-form--button
  grid-area: button

@import ../environment

.course-units-list
  display: grid
  grid-template-columns: 1fr
  gap: spacer(4)

  +media-breakpoint-up(sm)
    grid-template-columns: 1fr 1fr

  +media-breakpoint-up(lg)
    grid-template-columns: 1fr 1fr 1fr

@import ../environment

.full-avatar
  width: 100%
  max-width: px-to-rem(200px)
  +media-breakpoint-up($tablet-from)
    max-width: px-to-rem(320px)
  +media-breakpoint-up(xl)
    max-width: px-to-rem(360px)
  +media-breakpoint-up(xxl)
    max-width: px-to-rem(400px)

  position: relative
  &::before
    content: ''
    display: block
    padding-top: 100% // square
    pointer-events: none

  > img
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

  &.-slide-from-left
    animation: full-avatar-slide-from-left 1.2s cubic-bezier(0.680, -0.550, 0.265, 1.25) both

    @media (prefers-reduced-motion)
      animation: none

  &.-text-slide
    margin-inline: auto
    max-height: $max-asset-height-on-mobile-slides

@keyframes full-avatar-slide-from-left
  0%
    transform: translateX(#{px-to-rem(-1000px)})
    opacity: 0

  100%
    transform: translateX(0)
    opacity: 1

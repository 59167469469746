@import ../environment

.incomplete-test-modal
  display: flex
  flex-direction: column
  text-align: center
  gap: $size-s

  padding: $size-2xs $size-2xs

  +media-breakpoint-down(md)
    padding: 0


.incomplete-test-modal--text
  color: $text-muted
  font-size: $lead-font-size


.incomplete-test-modal--buttons
  display: flex
  justify-content: center
  gap: $size-xs

  +media-breakpoint-down(md)
    flex-direction: column
    align-items: center

:root {
  --icon-line-height: var(--bs-body-line-height);
}

.icon {
  display: inline-grid;
  align-items: center;
  gap: 0.35em;
  vertical-align: top;
  min-height: calc(1em * var(--icon-line-height));
}
button .icon {
  pointer-events: none;
}

.icon--image {
  grid-row: 1;
  grid-column: 1;
  fill: currentColor;
  width: 1em;
  height: 1em;
}

.icon--text {
  grid-row: 1;
  grid-column: 2;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcmFkZmFocmF1c2JpbGR1bmdfcC9yZWxlYXNlcy8yMDI1MDIwNTEwNDYzNS9hcHAvYXNzZXRzL3NoYXJlZC9jb21wb25lbnRzIiwic291cmNlcyI6WyJpY29uLnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUM7RUFDQzs7O0FBRUY7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBOztBQUVBO0VBQ0U7OztBQUVKO0VBQ0U7RUFDQTtFQUVBO0VBQ0E7RUFDQTs7O0FBRUY7RUFDRTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiXFw6cm9vdFxuICAtLWljb24tbGluZS1oZWlnaHQ6IHZhcigtLWJzLWJvZHktbGluZS1oZWlnaHQpXG5cbi5pY29uXG4gIGRpc3BsYXk6IGlubGluZS1ncmlkXG4gIGFsaWduLWl0ZW1zOiBjZW50ZXJcbiAgZ2FwOiAwLjM1ZW0gLy8gbW9yZSB0aGFuIGEgc3BhY2UgY2hhcmFjdGVyXG4gIHZlcnRpY2FsLWFsaWduOiB0b3BcbiAgbWluLWhlaWdodDogY2FsYygxZW0gKiB2YXIoLS1pY29uLWxpbmUtaGVpZ2h0KSlcblxuICBidXR0b24gJlxuICAgIHBvaW50ZXItZXZlbnRzOiBub25lXG5cbi5pY29uLS1pbWFnZVxuICBncmlkLXJvdzogMVxuICBncmlkLWNvbHVtbjogMVxuXG4gIGZpbGw6IGN1cnJlbnRDb2xvclxuICB3aWR0aDogMWVtXG4gIGhlaWdodDogMWVtXG5cbi5pY29uLS10ZXh0XG4gIGdyaWQtcm93OiAxXG4gIGdyaWQtY29sdW1uOiAyXG4iXX0= */
@import ../environment
@import ../config/authentication_box

.signed-out-confirmation
  +authentication-box

.signed-out-confirmation--icon
  --animated-icon-size: #{px-to-rem(90px)}
  text-align: center
  margin-bottom: $size-xs

.signed-out-confirmation--heading
  +authentication-box--heading($margin: $size-xs)

.signed-out-confirmation--buttons
  +authentication-box--buttons($margin: $size-s)

@use "sass:list"
@use "sass:math"
@import bootstrap/scss/mixins
@import ../../shared/config/mixins

=dragged-nugget-styles
  &.sortable-chosen
    // Element that was picked up. Also receives .sortable-drag in some cases, but not always (e.g. when swapping)
    --nugget-border-color: #{$brand-green}

  &.sortable-drag
    // Element being dragged around; we can't change opacity or use box-shadow, because of [draggable]
    --nugget-border-color: #{$brand-green}

  &.sortable-ghost
    // Placeholder at the drop location
    --nugget-border-color: transparent
    opacity: 0.5

  &.sortable-swap-highlight
    // Other element that will be swapped out by the dragged element; only relevant when using `swap: true`.
    --nugget-border-color: #{$brand-green}

  &.sortable-dropped
    animation: nugget-placed .3s $brand-transition-timing-function


=banner($background, $color)
  $stripe: mix($background, $dark, 90%)

  --height: var(--banner-height, var(--spacer-4))
  height: var(--height)
  line-height: var(--height)

  text-align: center
  font-weight: $font-weight-bold
  font-size: $small-font-size

  color: $color

  background: $background linear-gradient(45deg, $stripe 25%, $background 25%, $background 50%, $stripe 50%, $stripe 75%, $background 75%, $background)
  background-size: 5rem 5rem
  border-bottom: 1px solid rgba(0, 0, 0, .15)

  white-space: nowrap
  overflow-y: hidden
  overflow-x: auto

  padding: 0 var(--spacer-2)

  +styled-scrollbar($size: 2px, $firefox-size: thin, $track-color: transparent)


=styled-scrollbar($size: $size-3xs, $firefox-size: auto, $track-color: $brand-dark-blue-100, $thumb-color: $brand-dark-blue-300, $margin: 0)
  @supports not selector(::-webkit-scrollbar)
    // Firefox
    scrollbar-color: $thumb-color $track-color
    scrollbar-width: $firefox-size

  // Chrome, Edge, and Safari
  &::-webkit-scrollbar
    width: $size // when vertical
    height: $size // when horizontal
    &-thumb
      background-color: $thumb-color
      border-radius: $size * .5
    &-track
      background-color: $track-color
      border-radius: $size * .5
      margin: $margin // applies only in scroll direction, i.e. no top/bottom margin on a horizontal scrollbar

=scrollbar-area($scrollbar-area: var(--container-padding), $horizontal: false)
  // $scrollbar-area should ideally correspond to the padding of the scrollable container
  // this mixin will reserve enough space at the edge of the container to place the
  // scrollbar centered and well visible
  --spacing: calc(#{$scrollbar-area} / 2)

  @if $horizontal
    padding-bottom: var(--spacing)
    margin-bottom: var(--spacing)
  @else
    padding-right: var(--spacing)
    margin-right: var(--spacing)


=focusable-tile($transition-more: (), $transform-more: ())
  box-shadow: $shadow-sm-blue-smaller-area
  transition: brand-transition(background-color, box-shadow, transform, $transition-more...)

  &:not([disabled])
    &:hover,
    &:focus,
    &:active,
    &:focus-visible
      background-color: $gray-200
      box-shadow: $shadow-sm-blue-smaller-area, 0 0 0 spacer(1) rgba($brand-dark-blue-300, 15%)
      outline: none

    &:active
      transform: list.append($transform-more, (scale(0.96)), $separator: space)

=ribbon-container
  overflow: hidden
  position: relative

=ribbon($background-colors-by-type: (), $default-background-color: $info, $color: $white)
  --background-color: #{$default-background-color}

  @each $type, $background-color in $background-colors-by-type
    &.-#{$type}
      --background-color: #{$background-color}

  position: absolute
  top: 0
  left: 0
  width: 10ch
  transform: translate(#{-100% * (1 - math.sin(45deg))}) rotate(-45deg)
  transform-origin: right top

  color: $color
  background-color: var(--background-color)

  // faux background left and right of element
  --background-extension: 2rem
  box-shadow: 0 0 0 var(--background-extension) var(--background-color)
  clip-path: inset(0 -100%)

  font-size: 0.6666rem
  font-weight: $font-weight-bold
  text-align: center
  text-transform: uppercase

  &::after
    content: ''
    position: absolute
    left: calc(-1 * var(--background-extension))
    right: calc(-1 * var(--background-extension))
    top: 0
    bottom: 0
    border: 1px solid rgba($dark, 0.3)

=ui-slide-tensioner
  // Element should have a minimum height to avoid navigation moving up or down when slide contents change.
  // However, on tall screens, we do not want our min-height to be too tall, but rather stop at a certain aspect ratio which still looks acceptable.
  // While we could achieve this through `min-height`, using a `::before` element is actually simpler: we can refer to the *current* element width (100%) and everything works.
  // Note that slides with lots of content should grow the element; in those cases, it's acceptable for the navigation to shift.
  --vertical-space: calc(var(--banner-height) + var(--navigation-height) + 2 * var(--content-margin))
  content: ''
  grid-column: 1 / -1
  grid-row: 1 / -1
  padding-top: min(calc(100% / (16 / 10)), calc(100vh - var(--vertical-space)))

=slide-base
  --padding-x: #{$size-s}
  +media-breakpoint-up(md)
    --padding-x: #{$size-xl}

  display: grid
  grid-template-columns: 1fr
  grid-template-rows: auto 1fr
  grid-template-areas: "notification" "body"

=slide-base--notification
  grid-area: notification

  --color: #{$brand-dark-blue}
  --background: inherit
  --border-color: var(--background, #{$gray-300})

  --app-icon-size: #{$size-xs}
  --app-icon-margin-right: var(--spacer-1)

  padding: var(--spacer-3) var(--padding-x)

  border-bottom: 1px solid var(--border-color)
  background: var(--background)
  color: var(--color)

  line-height: 1.25
  text-align: center

  transition: brand-transition(color, background, border)

  +media-breakpoint-down($fullscreen-course-ui-until)
    padding: var(--spacer-2) var(--container-padding)

  &.-success
    --color: #{$white}
    --background: #{$success}

    font-weight: bold

  &.-failure
    --color: #{$white}
    --background: #{$danger}

    font-weight: bold

  &.-warning
    --color: #{$warning}

    font-weight: bold

  &.-locked
    --color: #{$white}
    --background: #{$info}

    font-weight: bold

=full-screen-ui
  position: fixed
  inset: 0
  top: var(--banner-height, 0px)
  gap: 0

=mobile-course-ui-asset-height
  min-height: 60px // this is only to hint to users on devices with very little height (e.g. horizontal phone) that there is content they could see if they rotate their device. horizontal phone can't be supported with the current designs.
  max-height: $max-asset-height-on-mobile-slides

@import ../environment
@import app_icons

.app-icon
  --size: var(--app-icon-size)
  --margin-left: var(--app-icon-margin-left, 0)
  --margin-right: var(--app-icon-margin-right, 0)
  --mask: var(--url) center / contain no-repeat

  display: inline-grid
  width: var(--size)
  height: var(--size)

  margin-left: var(--margin-left)
  margin-right: var(--margin-right)

  vertical-align: calc(.25em - .45 * var(--size))

  background-color: currentColor

  -webkit-mask: var(--mask)
  mask:  var(--mask)

  --url: svg-uri('<svg xmlns="http://www.w3.org/2000/svg" width="1" height="1"><rect width="1" height="1" fill="#000" /></svg>')
  @each $name, $filename in $app-icons
    &.-#{$name}
      --url: url("@material-symbols/svg-400/rounded/#{$filename}.svg")

@import ../../environment

.container
  +make-container($container-padding-mobile * 2)

  +media-breakpoint-up(md)
    +make-container($container-padding-tablet * 2)
    max-width: $container-max-width-incl-padding


\:root
  --container-padding: #{$container-padding-mobile}
  +media-breakpoint-up(md)
    --container-padding: #{$container-padding-tablet}

  --container-content-width: min(calc(100% - var(--container-padding) * 2), #{$container-content-max-width})

@import ../environment

.read-section
  --slide-radius: #{$slide-border-radius}
  --border-spacing: #{$size-3xs}

  background-color: $brand-green-50
  border-radius: calc(var(--slide-radius) + var(--border-spacing))
  margin-bottom: $size-3xl
  padding: var(--border-spacing)

.read-section--slide
  background-color: $white
  border-radius: var(--slide-radius)
  overflow: hidden

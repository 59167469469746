@import ../environment

.lightbox-image-container
  width: 100%
  display: inline-block
  cursor: zoom-in
  position: relative

.lightbox-image-container--button
  position: absolute
  bottom: px-to-rem(10px)
  right: px-to-rem(10px)

  box-shadow: $shadow-hard

.error-page {
  display: grid;
  gap: var(--spacer-3) 0;
  grid-template-areas: "heading" "image" "message" "actions";
  justify-items: center;
  margin-inline: auto;
}
@media (min-width: 576px) {
  .error-page {
    grid-template-columns: auto var(--grid-gutter-width) auto;
    grid-template-rows: auto auto 1fr;
    grid-template-areas: "image . heading" "image . message" "image . actions";
    justify-content: center;
    justify-items: start;
  }
}

.error-page--image {
  grid-area: image;
  width: 10rem;
  text-align: center;
}
.error-page--image > img {
  width: 100%;
}

.error-page--heading {
  grid-area: heading;
  color: var(--bs-danger);
  margin: 0;
}

.error-page--message {
  grid-area: message;
}

.error-page--actions {
  grid-area: actions;
  align-self: start;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcmFkZmFocmF1c2JpbGR1bmdfcC9yZWxlYXNlcy8yMDI1MDIwNTEwNDYzNS9hcHAvYXNzZXRzL3NoYXJlZC9jb21wb25lbnRzIiwic291cmNlcyI6WyJlcnJvcl9wYWdlLnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBSUE7RUFDRTtFQUNBO0VBRUE7RUFDQTtFQVNBOztBQWpCQTtFQUdGO0lBUUk7SUFDQTtJQUNBO0lBQ0E7SUFDQTs7OztBQUlKO0VBQ0U7RUFDQTtFQUNBOztBQUVBO0VBQ0U7OztBQUVKO0VBQ0U7RUFFQTtFQUNBOzs7QUFFRjtFQUNFOzs7QUFFRjtFQUNFO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyI9aG9yaXpvbnRhbFxuICBAbWVkaWEgKG1pbi13aWR0aDogNTc2cHgpXG4gICAgQGNvbnRlbnRcblxuLmVycm9yLXBhZ2VcbiAgZGlzcGxheTogZ3JpZFxuICBnYXA6IHZhcigtLXNwYWNlci0zKSAwXG5cbiAgZ3JpZC10ZW1wbGF0ZS1hcmVhczogXCJoZWFkaW5nXCIgXCJpbWFnZVwiIFwibWVzc2FnZVwiIFwiYWN0aW9uc1wiXG4gIGp1c3RpZnktaXRlbXM6IGNlbnRlclxuXG4gICtob3Jpem9udGFsXG4gICAgZ3JpZC10ZW1wbGF0ZS1jb2x1bW5zOiBhdXRvIHZhcigtLWdyaWQtZ3V0dGVyLXdpZHRoKSBhdXRvXG4gICAgZ3JpZC10ZW1wbGF0ZS1yb3dzOiBhdXRvIGF1dG8gMWZyXG4gICAgZ3JpZC10ZW1wbGF0ZS1hcmVhczogXCJpbWFnZSAuIGhlYWRpbmdcIiBcImltYWdlIC4gbWVzc2FnZVwiIFwiaW1hZ2UgLiBhY3Rpb25zXCJcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlclxuICAgIGp1c3RpZnktaXRlbXM6IHN0YXJ0XG5cbiAgbWFyZ2luLWlubGluZTogYXV0b1xuXG4uZXJyb3ItcGFnZS0taW1hZ2VcbiAgZ3JpZC1hcmVhOiBpbWFnZVxuICB3aWR0aDogMTByZW1cbiAgdGV4dC1hbGlnbjogY2VudGVyXG5cbiAgPiBpbWdcbiAgICB3aWR0aDogMTAwJVxuXG4uZXJyb3ItcGFnZS0taGVhZGluZ1xuICBncmlkLWFyZWE6IGhlYWRpbmdcblxuICBjb2xvcjogdmFyKC0tYnMtZGFuZ2VyKVxuICBtYXJnaW46IDBcblxuLmVycm9yLXBhZ2UtLW1lc3NhZ2VcbiAgZ3JpZC1hcmVhOiBtZXNzYWdlXG5cbi5lcnJvci1wYWdlLS1hY3Rpb25zXG4gIGdyaWQtYXJlYTogYWN0aW9uc1xuICBhbGlnbi1zZWxmOiBzdGFydFxuIl19 */
@import ../environment

.slide-icon
  border-radius: 0.5 * $border-radius
  padding: $size-2xs $size-xs

  background-color: rgba($slide-overview-default, 0.1)
  color: $slide-overview-default

  &.-test-question
    background-color: rgba($slide-overview-question, 0.1)
    color: $slide-overview-question

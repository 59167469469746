@import ../environment

.nav-signed-in
  --app-icon-size: #{px-to-rem(24px)}
  --bs-nav-link-padding-y: 0
  --bs-navbar-brand-padding-y: 0

  background-color: $white

  height: var(--header-height)
  padding-top: var(--header-nav-padding-y)
  padding-bottom: var(--header-nav-padding-y)

@import ../environment

$horizontal-from: lg

.authentication-container
  display: grid

  grid-template-columns: 1fr
  grid-template-rows: auto auto auto auto 1fr
  grid-template-areas: "header" "content" "image-start" "image-end" "links"

  +media-breakpoint-up($horizontal-from)
    --content-width: var(--container-max-xl)

    --col-1: #{col-width(1, var(--content-width))}
    --col-4: #{col-width(4, var(--content-width))}
    --container-content-width: #{col-width(6, var(--content-width))}

    grid-template-columns: 1fr var(--col-1) var(--col-4) var(--col-1) 1fr
    grid-template-rows: 1fr auto auto auto auto 2fr
    grid-template-areas: ". . . . ." ". header header header ." "image-start content content content ." "links links links links links" ". . . . ." ". image-end . . ."
    gap: 0 var(--grid-gutter-width)
    margin-left: calc(-1 * var(--container-padding))
    margin-right: calc(-1 * var(--container-padding))

.authentication-container--header
  grid-area: header

.authentication-container--content
  grid-area: content

  +media-breakpoint-down($horizontal-from)
    width: 100%
    max-width: col-width(6, var(--container-max-lg))
    margin-inline: auto

    --heading-font-size: #{$h2-font-size}
    @media (max-width: 460px)
      --heading-font-size: 5.5vw
    @media (max-width: 430px)
      --heading-font-size: 5.25vw
    @media (max-width: 400px)
      --heading-font-size: max(5vw, #{$h3-font-size})

.authentication-container--image
  grid-column: image-start / image-end
  grid-row: image-start / image-end
  align-self: end

  z-index: -1

  text-align: center
  > img
    width: 100%
    max-width: px-to-rem(400px)

.authentication-container--links
  grid-area: links

  margin-block: $size-m

@import ../environment

.footer-logos
  display: flex
  flex-wrap: wrap
  align-items: center
  justify-content: center
  gap: 2 * $size-2xs

  padding-top: var(--spacer-4)
  padding-bottom: var(--spacer-4)

  a
    display: inline-block
    padding: $size-4xs

    > img
      height: px-to-rem(50px)

.test-question-layout {
  display: flex;
  justify-content: center;
  gap: var(--spacer-5);
}

.test-question-layout--image {
  width: 50%;
  display: flex;
  align-items: center;
}

.test-question-layout--text {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.test-question-layout--explanation {
  margin-top: var(--spacer-4);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcmFkZmFocmF1c2JpbGR1bmdfcC9yZWxlYXNlcy8yMDI1MDIwNTEwNDYzNS9hcHAvYXNzZXRzL2Zyb250ZW5kL2NvbXBvbmVudHMiLCJzb3VyY2VzIjpbInRlc3RfcXVlc3Rpb25fbGF5b3V0LnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRTtFQUNBO0VBQ0E7OztBQUVGO0VBQ0U7RUFDQTtFQUNBOzs7QUFFRjtFQUNFO0VBQ0E7RUFDQTtFQUNBOzs7QUFFRjtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCAuLi9lbnZpcm9ubWVudFxuXG4udGVzdC1xdWVzdGlvbi1sYXlvdXRcbiAgZGlzcGxheTogZmxleFxuICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlclxuICBnYXA6IHZhcigtLXNwYWNlci01KVxuXG4udGVzdC1xdWVzdGlvbi1sYXlvdXQtLWltYWdlXG4gIHdpZHRoOiA1MCVcbiAgZGlzcGxheTogZmxleFxuICBhbGlnbi1pdGVtczogY2VudGVyXG5cbi50ZXN0LXF1ZXN0aW9uLWxheW91dC0tdGV4dFxuICB3aWR0aDogNTAlXG4gIGRpc3BsYXk6IGZsZXhcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtblxuICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlclxuXG4udGVzdC1xdWVzdGlvbi1sYXlvdXQtLWV4cGxhbmF0aW9uXG4gIG1hcmdpbi10b3A6IHZhcigtLXNwYWNlci00KVxuIl19 */
@import ../environment

.course-navigation
  --app-icon-size: #{$size-xs}

  display: grid
  grid-template-areas: "exit . back progress forward . submit"
  grid-template-columns: auto 1fr auto minmax(auto, 6rem) auto 1fr auto
  grid-template-rows: auto
  gap: var(--spacer-3) var(--spacer-1)
  align-items: center

  +media-breakpoint-up($tablet-from)
    // back/progress/forward is centered, because exit and submit button containers have same width
    grid-template-areas: "exit back progress forward submit"
    grid-template-columns: 1fr auto 10rem auto 1fr

.course-navigation--exit
  grid-area: exit
  justify-self: start

.course-navigation--back
  grid-area: back
  > .btn
    @extend %pagination-button

.course-navigation--progress
  grid-area: progress

.course-navigation--forward
  grid-area: forward
  > .btn
    @extend %pagination-button

.course-navigation--submit
  grid-area: submit
  justify-self: end

%pagination-button
  --bs-btn-padding-x: var(--spacer-2)
  --bs-btn-padding-y: var(--spacer-1)
  --bs-btn-disabled-color: #{$brand-dark-blue-50}
  --bs-link-color: #{$brand-dark-blue-100}
  --bs-link-hover-color: #{$brand-dark-blue-100}
  --bs-btn-disabled-opacity: 1

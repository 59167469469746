@import ../environment

.course-unit-table-of-contents
  display: flex
  flex-direction: column

.course-unit-table-of-contents--section
  border-bottom: 1px solid $gray-300
  padding-block: var(--spacer-3)

  &:last-child
    border-bottom: none

@import ../environment

.slide-overview-card
  display: grid
  grid-template-columns: auto 1fr auto
  grid-template-areas: "icon title expand-button" "body body body"
  grid-column-gap: var(--spacer-4)
  align-items: center

  background-color: $white
  border-radius: $border-radius
  border: 1px solid $border-color
  margin-bottom: var(--spacer-3)
  padding: var(--spacer-3)

  +media-breakpoint-up(lg)
    display: none

.slide-overview-card--icon
  grid-area: icon

.slide-overview-card--expand-button
  grid-area: expand-button

.slide-overview-card--title
  grid-area: title

  font-family: $headings-font-family
  font-weight: $headings-font-weight
  +font-size($h3-font-size)
  line-height: $headings-line-height
  color: $brand-dark-blue

.slide-overview-card--body
  grid-area: body

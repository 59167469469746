@charset "UTF-8";
.translated-text {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.translated-text > * {
  flex-grow: 1;
}
.translated-text.-smaller-with-translation:has(.-has-translations) {
  font-size: max(0.75em, 0.875rem);
}
.translated-text.-horizontal {
  display: inline flex;
  column-gap: 1rem;
  flex-direction: row;
  flex-wrap: wrap;
  width: max-content;
  max-width: 100%;
}
.translated-text.-inline {
  display: inline flow;
  width: auto;
}
.translated-text.-inline > * {
  display: inline;
}
label:has(.translated-text) {
  width: 100%;
}
.translated-text.-with-separator:has(.-has-translations) .translated-text--original::after {
  display: inline flow-root;
  content: "·";
  text-align: center;
  width: 1rem;
}

.translated-text--translation {
  font-weight: normal;
  filter: opacity(60%);
}
.translated-text--translation.-keep-color {
  filter: none;
}
.translated-text--translation.-smaller {
  font-size: max(0.75em, 0.875rem);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcmFkZmFocmF1c2JpbGR1bmdfcC9yZWxlYXNlcy8yMDI1MDIwNTEwNDYzNS9hcHAvYXNzZXRzL2Zyb250ZW5kL2NvbXBvbmVudHMiLCJzb3VyY2VzIjpbInRyYW5zbGF0ZWRfdGV4dC5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiI7QUFFQTtFQUNFO0VBQ0E7RUFDQTs7QUFFQTtFQUNFOztBQUVGO0VBQ0U7O0FBRUY7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBRUY7RUFDRTtFQUNBOztBQUVBO0VBQ0U7O0FBRUo7RUFDRTs7QUFFRjtFQUNFO0VBQ0E7RUFDQTtFQUNBOzs7QUFHSjtFQUNFO0VBQ0E7O0FBRUE7RUFDRTs7QUFFRjtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCAuLi9lbnZpcm9ubWVudFxuXG4udHJhbnNsYXRlZC10ZXh0XG4gIGRpc3BsYXk6IGZsZXhcbiAgZmxleC1kaXJlY3Rpb246IGNvbHVtblxuICB3aWR0aDogMTAwJVxuXG4gID4gKlxuICAgIGZsZXgtZ3JvdzogMVxuXG4gICYuLXNtYWxsZXItd2l0aC10cmFuc2xhdGlvbjpoYXMoLi1oYXMtdHJhbnNsYXRpb25zKVxuICAgIGZvbnQtc2l6ZTogbWF4KDAuNzVlbSwgLjg3NXJlbSlcblxuICAmLi1ob3Jpem9udGFsXG4gICAgZGlzcGxheTogaW5saW5lIGZsZXhcbiAgICBjb2x1bW4tZ2FwOiBzcGFjZXIoMylcbiAgICBmbGV4LWRpcmVjdGlvbjogcm93XG4gICAgZmxleC13cmFwOiB3cmFwXG4gICAgd2lkdGg6IG1heC1jb250ZW50XG4gICAgbWF4LXdpZHRoOiAxMDAlXG5cbiAgJi4taW5saW5lXG4gICAgZGlzcGxheTogaW5saW5lIGZsb3dcbiAgICB3aWR0aDogYXV0b1xuXG4gICAgPiAqXG4gICAgICBkaXNwbGF5OiBpbmxpbmVcblxuICBsYWJlbDpoYXMoJilcbiAgICB3aWR0aDogMTAwJVxuXG4gICYuLXdpdGgtc2VwYXJhdG9yOmhhcyguLWhhcy10cmFuc2xhdGlvbnMpIC50cmFuc2xhdGVkLXRleHQtLW9yaWdpbmFsOjphZnRlclxuICAgIGRpc3BsYXk6IGlubGluZSBmbG93LXJvb3RcbiAgICBjb250ZW50OiAnwrcnXG4gICAgdGV4dC1hbGlnbjogY2VudGVyXG4gICAgd2lkdGg6IHNwYWNlcigzKVxuXG5cbi50cmFuc2xhdGVkLXRleHQtLXRyYW5zbGF0aW9uXG4gIGZvbnQtd2VpZ2h0OiBub3JtYWxcbiAgZmlsdGVyOiBvcGFjaXR5KDYwJSlcblxuICAmLi1rZWVwLWNvbG9yXG4gICAgZmlsdGVyOiBub25lXG5cbiAgJi4tc21hbGxlclxuICAgIGZvbnQtc2l6ZTogbWF4KDAuNzVlbSwgLjg3NXJlbSlcbiJdfQ== */
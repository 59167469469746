@import ../environment

.topic-hero
  --content-margin: var(--spacer-4)

.topic-hero--image
  width: 100%
  padding-top: var(--spacer-5)
  padding-bottom: var(--spacer-4)

  +media-breakpoint-up(lg)
    padding-top: unset
    padding-bottom: unset

.topic-hero--subtitle
  display: none

  +media-breakpoint-up(lg)
    display: block
    color: var(--bs-gray-700)
    margin-top: var(--spacer-5)
    margin-bottom: 0

.topic-hero--title
  margin-top: var(--spacer-5)
  +media-breakpoint-up(lg)
    margin-top: unset

.topic-hero--link
  --bs-link-color: #{$primary}
  --bs-link-hover-color: #{$brand-dark-green}

.topic-hero--course-units
  margin-top: var(--spacer-3)

.topic-hero--text
  text-align: center
  align-self: center

  +media-breakpoint-up(lg)
    text-align: unset


.new-account {
  display: grid;
}

.new-account--heading {
  margin: 0 0 1.25rem;
}

.new-account--intro {
  margin: 0 0 2.25rem;
}

.new-account--info {
  margin: 0 0 2.25rem;
}

.new-account--cancel {
  margin: 0.75rem 0 0;
  text-align: center;
  --bs-link-color: #777777;
  --bs-link-hover-color: #121212;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcmFkZmFocmF1c2JpbGR1bmdfcC9yZWxlYXNlcy8yMDI1MDIwNTEwNDYzNS9hcHAvYXNzZXRzL2Zyb250ZW5kL2NvbXBvbmVudHMiLCJzb3VyY2VzIjpbIm5ld19hY2NvdW50LnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRTs7O0FBRUY7RUFDRTs7O0FBRUY7RUFDRTs7O0FBRUY7RUFDRTs7O0FBRUY7RUFDRTtFQUNBO0VBRUE7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgLi4vZW52aXJvbm1lbnRcblxuLm5ldy1hY2NvdW50XG4gIGRpc3BsYXk6IGdyaWRcblxuLm5ldy1hY2NvdW50LS1oZWFkaW5nXG4gIG1hcmdpbjogMCAwICRzaXplLXhzXG5cbi5uZXctYWNjb3VudC0taW50cm9cbiAgbWFyZ2luOiAwIDAgJHNpemUtbVxuXG4ubmV3LWFjY291bnQtLWluZm9cbiAgbWFyZ2luOiAwIDAgJHNpemUtbVxuXG4ubmV3LWFjY291bnQtLWNhbmNlbFxuICBtYXJnaW46ICRzaXplLTJ4cyAwIDBcbiAgdGV4dC1hbGlnbjogY2VudGVyXG5cbiAgLS1icy1saW5rLWNvbG9yOiAjeyRncmF5LTcwMH1cbiAgLS1icy1saW5rLWhvdmVyLWNvbG9yOiAjeyRib2R5LWNvbG9yfVxuIl19 */
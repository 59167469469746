@import ../environment

.content-tile
  --spacer: #{$size-xs}
  --icon-container-size: #{$size-xl}
  --app-icon-size: #{$size-s}

  display: grid
  align-items: center
  grid-template-columns: var(--icon-container-size) 1fr
  grid-template-areas: "icon text"
  gap: var(--spacer)

  padding: var(--spacer)
  background-color: $white
  border-radius: $border-radius

  +focusable-tile
  +ribbon-container

.content-tile--icon
  width: var(--icon-container-size)
  height: var(--icon-container-size)
  border-radius: 100vmax
  background-color: $brand-green-50
  color: $brand-green

  display: grid
  place-content: center

.content-tile--text
  color: $brand-dark-blue
  font-weight: $font-weight-bold
  line-height: 1.125

.content-tile--ribbon
  +ribbon($course-unit-ribbon-colors)

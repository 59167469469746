.video-slide {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "title" "text" "play-button";
  gap: var(--spacer-3);
}

.video-slide--title {
  grid-area: title;
}

.video-slide--text {
  grid-area: text;
}

.video-slide--play-button {
  grid-area: play-button;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcmFkZmFocmF1c2JpbGR1bmdfcC9yZWxlYXNlcy8yMDI1MDIwNTEwNDYzNS9hcHAvYXNzZXRzL2Zyb250ZW5kL2NvbXBvbmVudHMiLCJzb3VyY2VzIjpbInZpZGVvX3NsaWRlLnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRTtFQUNBO0VBQ0E7RUFDQTs7O0FBRUY7RUFDRTs7O0FBRUY7RUFDRTs7O0FBRUY7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgLi4vZW52aXJvbm1lbnRcblxuLnZpZGVvLXNsaWRlXG4gIGRpc3BsYXk6IGdyaWRcbiAgZ3JpZC10ZW1wbGF0ZS1jb2x1bW5zOiAxZnJcbiAgZ3JpZC10ZW1wbGF0ZS1hcmVhczogJ3RpdGxlJyAndGV4dCcgJ3BsYXktYnV0dG9uJ1xuICBnYXA6IHZhcigtLXNwYWNlci0zKVxuXG4udmlkZW8tc2xpZGUtLXRpdGxlXG4gIGdyaWQtYXJlYTogdGl0bGVcblxuLnZpZGVvLXNsaWRlLS10ZXh0XG4gIGdyaWQtYXJlYTogdGV4dFxuXG4udmlkZW8tc2xpZGUtLXBsYXktYnV0dG9uXG4gIGdyaWQtYXJlYTogcGxheS1idXR0b25cbiJdfQ== */
.form-check-input {
  cursor: pointer;
}
.form-check-input:disabled {
  cursor: not-allowed;
}

.form-check-label {
  cursor: pointer;
}
input:disabled ~ .form-check-label {
  cursor: not-allowed;
}

.col-form-label .form-check, .input-group-text .form-check {
  margin-bottom: 0;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcmFkZmFocmF1c2JpbGR1bmdfcC9yZWxlYXNlcy8yMDI1MDIwNTEwNDYzNS9hcHAvYXNzZXRzL3NoYXJlZC9jb21wb25lbnRzL2Jvb3RzdHJhcF9leHQiLCJzb3VyY2VzIjpbImZvcm1fY2hlY2suc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFOztBQUVBO0VBQ0U7OztBQUdKO0VBQ0U7O0FBRUE7RUFDRTs7O0FBSUY7RUFFRSIsInNvdXJjZXNDb250ZW50IjpbIi5mb3JtLWNoZWNrLWlucHV0XG4gIGN1cnNvcjogcG9pbnRlclxuXG4gICY6ZGlzYWJsZWRcbiAgICBjdXJzb3I6IG5vdC1hbGxvd2VkXG5cblxuLmZvcm0tY2hlY2stbGFiZWxcbiAgY3Vyc29yOiBwb2ludGVyXG5cbiAgaW5wdXQ6ZGlzYWJsZWQgfiAmXG4gICAgY3Vyc29yOiBub3QtYWxsb3dlZFxuXG5cbi5mb3JtLWNoZWNrXG4gIC5jb2wtZm9ybS1sYWJlbCAmLFxuICAuaW5wdXQtZ3JvdXAtdGV4dCAmXG4gICAgbWFyZ2luLWJvdHRvbTogMFxuIl19 */
@import ../environment

.grid-game
  display: grid
  grid-template-columns: repeat(var(--grid-columns), auto)
  grid-template-rows: auto
  justify-content: center

  gap: var(--spacer-2)

  +media-breakpoint-up($tablet-from)
    grid-template-rows: auto repeat(var(--grid-rows), 1fr) // 1st row are column hints, further rows are game cells

.grid-game--column-hint
  --app-icon-size: #{$size-m}
  grid-row: 1
  justify-self: center

  &[title]
    cursor: help
    color: $brand-dark-blue
    transition: brand-transition(color)
    filter: drop-shadow(2px 2px 2px rgba($brand-dark-blue, .2))
    &:hover
      color: $brand-blue

  &.-shuffled
    animation: shuffled-column-hint 4s $brand-transition-timing-function infinite
    @media (prefers-reduced-motion)
      animation: none

    &.-muted
      filter: none
      animation: none

  &.-hidden
    height: 0
    overflow: hidden
    pointer-events: none


.grid-game--cell
  display: grid // stretch contents

  &.-incorrect
    --nugget-color: #{$brand-orange}
    --nugget-background: #{$brand-orange-50}
    --nugget-cursor: #{''}

  &.-correct
    --nugget-color: #{$brand-green}
    --nugget-background: #{$brand-green-50}
    --nugget-cursor: #{''}


@keyframes shuffled-column-hint
  0%
    transform: translateY(0)
  5%
    transform: translateY($size-3xs)
  10%
    transform: translateY(0)
  15%
    transform: translateY($size-3xs)
  20%
    transform: translateY(0)
  100%
    transform: translateY(0)

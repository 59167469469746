@use "sass:math"
@import ../environment

$button-size: $course-unit-scroller-button-size
$button-gap: $course-unit-scroller-button-gap
$scrollbar-height: spacer(2)

.course-units-scroller
  --tiles-gap: var(--grid-gutter-width)
  --drawable-overflow: var(--course-units-scroller--drawable-overflow, var(--container-padding))
  --button-area-size: var(--container-padding)

  --left-margin: calc(-1 * var(--container-padding))
  --right-margin: calc(-1 * var(--container-padding))

  padding-top: var(--drawable-overflow)
  padding-bottom: var(--drawable-overflow)

  margin-top: calc(-1 * var(--drawable-overflow))
  margin-bottom: 0
  margin-left: var(--left-margin)
  margin-right: var(--right-margin)

  display: grid
  grid-template-columns: var(--button-area-size) 1fr var(--button-area-size)
  grid-template-areas: "previous-button tiles next-button"

  +media-breakpoint-up($desktop-from)
    $button-area-size: $button-size + 2 * $button-gap
    --button-area-size: #{$button-area-size}
    --left-margin: #{-1 * $button-area-size}

    @media (min-width: $container-max-width-incl-padding)
      @media (max-width: $container-max-width-incl-padding + $button-area-size)
        --space-beyond-container-incl-padding: calc((100vw - #{$container-max-width-incl-padding}))
        --right-margin: calc(-0.5 * var(--space-beyond-container-incl-padding) - var(--container-padding))

      @media (min-width: $container-max-width-incl-padding + $button-area-size)
        --right-margin: #{-1 * $button-area-size}

    &.-no-smart-button-placement
      --right-margin: #{-1 * $button-area-size}


.course-units-scroller--tiles
  grid-area: tiles

  display: grid
  grid-template-rows: auto
  +media-breakpoint-up($tablet-from)
    --rows: var(--scroller-rows, 2)
    grid-template-rows: repeat(var(--rows), auto)
  grid-auto-flow: column
  place-content: start
  gap: var(--tiles-gap)

  // allow shadows to render while this element has "overflow" set
  --vertical-overflow: #{$size-xs}
  padding: var(--vertical-overflow) var(--drawable-overflow)
  margin: calc(-1 * var(--vertical-overflow)) calc(-1 * var(--drawable-overflow))

  overflow-x: auto
  +styled-scrollbar($margin: var(--drawable-overflow))

  scroll-snap-type: x mandatory
  > *
    scroll-snap-align: start
    scroll-margin-left: var(--drawable-overflow)

  // fade out contents at left side
  --fade-out-mask-image: linear-gradient(to right, transparent 0%, transparent calc(var(--drawable-overflow) / 2), black var(--drawable-overflow), black 100%)
  +media-breakpoint-up($desktop-from)
    // on desktop, also fade out right side (visible on desktop < 1480px width)
    --fade-out-mask-image: linear-gradient(to right, transparent 0%, black calc(var(--drawable-overflow) / 2), black calc(100% - var(--drawable-overflow) / 2), transparent 100%)
  --fade-out-mask: var(--fade-out-mask-image) left top / 100% 100% no-repeat
  -webkit-mask: var(--fade-out-mask)
  mask: var(--fade-out-mask)

.course-units-scroller--page-button
  --app-icon-size: #{px-to-rem(32px)}

  align-self: center
  justify-self: center
  &.-previous
    grid-area: previous-button
  &.-next
    grid-area: next-button

  display: none
  +media-breakpoint-up($desktop-from)
    display: block
    z-index: 1

    border: none
    background-color: $white
    width: $button-size
    height: $button-size
    border-radius: 100vmax
    padding: 0
    margin: 0 0 $scrollbar-height

    opacity: 1
    +focusable-tile($transition-more: opacity)

    &:disabled
      color: inherit
      opacity: 0

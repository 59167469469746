@import ../environment

$horizontal-from: lg

.video-slide-container
  max-height: 100%

  display: flex
  flex-direction: column

  +media-breakpoint-up($horizontal-from)
    display: grid
    grid-template-columns: 1fr 1fr
    grid-template-areas: 'asset body'
    gap: 0 $size-xl


.video-slide-container--asset
  grid-area: asset
  width: 100%

  +media-breakpoint-down($horizontal-from)
    width: fit-content
    margin: auto

    img,
    video
      min-height: 20px
      max-height: $max-asset-height-on-mobile-slides
      object-fit: contain

.video-slide-container--body
  --padding: var(--container-padding)
  grid-area: body
  margin-top: $size-xs

  +media-breakpoint-down($fullscreen-course-ui-until)
    margin: 0
    padding: var(--padding)
    overflow: auto

    +scrollbar-area(var(--padding))
    +styled-scrollbar($size: $size-4xs, $margin: var(--container-padding))

  +media-breakpoint-up($horizontal-from)
    align-self: center
    padding: 0

up-popup {
  margin-top: 1rem;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  text-align: start;
}
up-popup[size=content] {
  width: fit-content;
  max-width: calc(100vw - 20px);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcmFkZmFocmF1c2JpbGR1bmdfcC9yZWxlYXNlcy8yMDI1MDIwNTEwNDYzNS9hcHAvYXNzZXRzL2Zyb250ZW5kL2NvbXBvbmVudHMvdW5wb2x5X2V4dCIsInNvdXJjZXMiOlsidXBfcG9wdXAuc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFQTtFQUNFO0VBQ0E7RUFDQTtFQUlBOztBQUVBO0VBQ0U7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIkBpbXBvcnQgLi4vLi4vZW52aXJvbm1lbnRcblxudXAtcG9wdXBcbiAgbWFyZ2luLXRvcDogMXJlbVxuICBib3JkZXItcmFkaXVzOiB2YXIoLS1ib3JkZXItcmFkaXVzKVxuICBib3gtc2hhZG93OiB2YXIoLS1ib3gtc2hhZG93KVxuXG4gIC8vIEZpeCBmb3IgYW4gVW5wb2x5IGJ1ZyB3aGVyZSBpdCBhc3NpZ25zIGBhbGlnbj1cInJpZ2h0XCJgIHRvIHJpZ2h0LWFsaWduZWQgcG9wdXAgY29udGFpbmVycy5cbiAgLy8gVW5mb3J0dW5hdGVseSwgdGhlIFwiYWxpZ25cIiBIVE1MIGF0dHJpYnV0ZSBpbXBsaWVzIGB0ZXh0LWFsaWduYCwgYW5kIHdlIGRvbid0IHdhbnQgdGhhdCBvbmUgdG8gY2hhbmdlLlxuICB0ZXh0LWFsaWduOiBzdGFydFxuXG4gICZbc2l6ZT0nY29udGVudCddXG4gICAgd2lkdGg6IGZpdC1jb250ZW50XG4gICAgbWF4LXdpZHRoOiBjYWxjKDEwMHZ3IC0gMjBweClcbiJdfQ== */
.app-icon-list {
  --list-gap: var(--spacer-2);
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  gap: var(--list-gap);
}
.app-icon-list > li {
  --bullet-margin: calc(var(--app-icon-size) + var(--spacer-2));
  margin-inline: 0;
  padding-left: var(--bullet-margin);
  position: relative;
}
.app-icon-list > li .app-icon {
  position: absolute;
  left: 0;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcmFkZmFocmF1c2JpbGR1bmdfcC9yZWxlYXNlcy8yMDI1MDIwNTEwNDYzNS9hcHAvYXNzZXRzL2Zyb250ZW5kL2NvbXBvbmVudHMiLCJzb3VyY2VzIjpbImFwcF9pY29uX2xpc3Quc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBRUE7RUFDQTtFQUNBO0VBRUE7RUFDQTs7QUFFQTtFQUNFO0VBRUE7RUFDQTtFQUNBOztBQUVBO0VBQ0U7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIi5hcHAtaWNvbi1saXN0XG4gIC0tbGlzdC1nYXA6IHZhcigtLXNwYWNlci0yKVxuXG4gIGxpc3Qtc3R5bGUtdHlwZTogbm9uZVxuICBtYXJnaW46IDBcbiAgcGFkZGluZzogMFxuXG4gIGRpc3BsYXk6IGdyaWRcbiAgZ2FwOiB2YXIoLS1saXN0LWdhcClcblxuICA+IGxpXG4gICAgLS1idWxsZXQtbWFyZ2luOiBjYWxjKHZhcigtLWFwcC1pY29uLXNpemUpICsgdmFyKC0tc3BhY2VyLTIpKVxuXG4gICAgbWFyZ2luLWlubGluZTogMFxuICAgIHBhZGRpbmctbGVmdDogdmFyKC0tYnVsbGV0LW1hcmdpbilcbiAgICBwb3NpdGlvbjogcmVsYXRpdmVcblxuICAgIC5hcHAtaWNvblxuICAgICAgcG9zaXRpb246IGFic29sdXRlXG4gICAgICBsZWZ0OiAwXG4iXX0= */
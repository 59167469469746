@import ../environment

.avatar
  --size: var(--avatar-size, #{$size-4xl})

  display: inline-grid
  place-content: center

  width: var(--size)
  height: var(--size)

  border: 1px solid $gray-400
  border-radius: 100%
  background-color: $white

  overflow: hidden

  &.-shadow
    box-shadow: $shadow-sm

  > img
    width: 100%
    height: 100%
    object-fit: cover

up-modal-box
  up-modal[size='small'] &
    width: var(--container-max-sm)
  up-modal[size='medium'] &
    width: var(--container-max-md)
  up-modal[size='large'] &
    width: var(--container-max-lg)
  up-modal[size='max-container'] &
    max-width: var(--container-max-xxl)

up-modal-box
  border-radius: var(--border-radius)
  box-shadow: var(--box-shadow)

up-progress-bar
  background-color: var(--bs-primary)
  box-shadow: -3px 0 2px 2px rgba(var(--bs-white-rgb), 80%)

up-modal-box
  padding: var(--spacer-4)

up-drawer-box,
up-cover-box,
up-popup
  padding: calc(var(--grid-gutter-width) / 2)

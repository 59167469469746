@import ../environment

.language-select
  max-width: 24rem

.language-select--select
  width: max-content
  outline: none
  border: none
  background-color: #fff
  cursor: pointer

  font-weight: bold
  color: $link-color
  padding: spacer(2) spacer(3)

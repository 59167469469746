.circle-marker {
  content: "";
  display: inline-block;
  width: 0.5625rem;
  height: 0.5625rem;
  border: 1px solid #B4B3C8;
  border-radius: 100vmax;
}
.circle-marker.checked {
  background-color: #3C91E6;
  border-color: #3C91E6;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcmFkZmFocmF1c2JpbGR1bmdfcC9yZWxlYXNlcy8yMDI1MDIwNTEwNDYzNS9hcHAvYXNzZXRzL2Zyb250ZW5kL2NvbXBvbmVudHMiLCJzb3VyY2VzIjpbImNpcmNsZV9tYXJrZXIuc2FzcyIsIi4uLy4uL3NoYXJlZC9jb25maWcvX2JyYW5kX2NvbG9ycy5zYXNzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUlBO0VBQ0U7RUFDQTtFQUNBLE9BTGtCO0VBTWxCLFFBTmtCO0VBT2xCO0VBQ0E7O0FBRUE7RUFDRSxrQkNGUztFREdULGNDSFMiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0IC4uL2Vudmlyb25tZW50XG5cbiRjaGVjay1jaXJjbGUtc2l6ZTogcHgtdG8tcmVtKDlweClcblxuLmNpcmNsZS1tYXJrZXJcbiAgY29udGVudCA6ICcnXG4gIGRpc3BsYXk6IGlubGluZS1ibG9ja1xuICB3aWR0aDogJGNoZWNrLWNpcmNsZS1zaXplXG4gIGhlaWdodDogJGNoZWNrLWNpcmNsZS1zaXplXG4gIGJvcmRlcjogMXB4IHNvbGlkICRicmFuZC1kYXJrLWJsdWUtMTAwXG4gIGJvcmRlci1yYWRpdXM6IDEwMHZtYXhcblxuICAmLmNoZWNrZWRcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiAkYnJhbmQtYmx1ZVxuICAgIGJvcmRlci1jb2xvcjogJGJyYW5kLWJsdWVcbiIsIiRicmFuZC1kYXJrLWdyZWVuOiAjMDU5NTFiIC8vIERWV1xuXG4vLyBodHRwczovL3d3dy5maWdtYS5jb20vZmlsZS9ESkROT0ppYXhUYVB2dU5QOHlqN0NtL1JhZGZhaHJhdXNiaWxkdW5nLVVJJTJGVVgtRGVzaWduP25vZGUtaWQ9ODI3JTNBOTg3MVxuXG4kYnJhbmQtZ3JlZW46ICM2NUMxNDVcbiRicmFuZC1ncmVlbi03NTogI0VCRjdFOVxuJGJyYW5kLWdyZWVuLTUwOiAjRjJGQUYwXG4kYnJhbmQtb3JhbmdlOiAjRTc3NjBFXG4kYnJhbmQtb3JhbmdlLTUwOiAjRkRGMkUwXG4kYnJhbmQtcmVkOiAjREEwMDAwXG4kYnJhbmQtcmVkLTUwOiAjRkZFOUU3XG4kYnJhbmQtYmx1ZTogIzNDOTFFNlxuJGJyYW5kLWRhcmstYmx1ZTogIzA0MDE0N1xuXG4kYnJhbmQtZGFyay1ibHVlLTMwMDogIzY4Njc5MVxuJGJyYW5kLWRhcmstYmx1ZS0xMDA6ICNCNEIzQzhcbiRicmFuZC1kYXJrLWJsdWUtNTA6ICNFNkU2RURcblxuJGJyYW5kLWxpZ2h0LWJsdWU6ICNGNEY3RkVcbiRicmFuZC1saWdodC1ibHVlLTIwMDogI0RBRTRGQ1xuJGJyYW5kLWxpZ2h0LWJsdWUtMTAwOiAjRThFRUZEXG5cbiRicmFuZC1vcmFuZ2UtZ3JhZGllbnQtc3RhcnQ6ICNGRjlGMEZcbiRicmFuZC1vcmFuZ2UtZ3JhZGllbnQtZW5kOiAjRkYzNTM1XG4iXX0= */
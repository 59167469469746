@import ../environment

.read-course-unit--heading
  margin-top: var(--spacer-5)

.read-course-unit--intro
  color: $text-muted
  margin-bottom: 2rem

.read-course-unit--slide-bodies
  +media-breakpoint-down(lg)
    display: none

.read-course-unit--table-of-contents
  +media-breakpoint-up(lg)
    display: none

@import ../environment

.user-test-slide
  +slide-base
  overflow: hidden




.user-test-slide--notification
  +slide-base--notification
  text-align: inherit

  display: none
  +media-breakpoint-up($fullscreen-test-ui-until)
    display: block


.user-test-slide--body
  grid-area: body
  margin: 0

  max-height: 100%
  min-height: 0 // allow element to shrink and contents to respect overflow (i.e. scroll)

  +media-breakpoint-up($fullscreen-test-ui-until)
    align-self: center
    margin: $size-s var(--padding-x)

  .slide-overview-card &
    margin-inline: 0

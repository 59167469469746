@import ../environment

$check-circle-size: px-to-rem(9px)

.circle-marker
  content : ''
  display: inline-block
  width: $check-circle-size
  height: $check-circle-size
  border: 1px solid $brand-dark-blue-100
  border-radius: 100vmax

  &.checked
    background-color: $brand-blue
    border-color: $brand-blue

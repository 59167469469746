@import ../environment

.slide
  +slide-base
  overflow: hidden


.slide--notification
  +slide-base--notification

  +media-breakpoint-up($fullscreen-course-ui-until)
    display: block


.slide--body
  grid-area: body

  max-height: 100%
  min-height: 0 // force into flex container

  +media-breakpoint-up($fullscreen-course-ui-until)
    align-self: center

    margin: $size-s var(--padding-x)
    position: relative

  .slide-overview-card &
    margin-inline: 0

  video
    display: block

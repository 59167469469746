@import ../environment

$radius: $border-radius-md

.course-completion
  display: grid
  grid-template-columns: 1fr
  grid-template-rows: 1fr auto
  grid-template-areas: "message" "recommended-courses"

  border-radius: $radius
  box-shadow: $shadow-sm-blue

.course-completion--message
  grid-area: message

  background-color: $white
  border-radius: $radius $radius 0 0

  padding: 0 var(--container-padding)

.course-completion--recommended-courses
  grid-area: recommended-courses

  background-color: $gray-200
  border-radius: 0 0 $radius $radius

  padding: $size-s var(--container-padding)

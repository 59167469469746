@import ../environment

.code-search-layout
  display: flex
  flex-direction: column
  align-items: center
  gap: $size-m
  margin-bottom: $size-s

  --qr-scanner-size: calc(min(100vw, 375px) - 2 * var(--container-padding))

  +media-breakpoint-up($horizontal-code-search-hero-from)
    // Element will not become wider than its LG max width.
    // We use 100vw instead of 100% because --qr-scanner-size is calculated from this,
    // and to set the scanner's width and height, this must not be a percent value.
    --max-width: min(calc(100vw - 2 * var(--container-padding)), var(--container-max-lg))

    --text-width: #{col-width(5, var(--max-width))}
    --spacer-width: #{col-width(1, var(--max-width))}
    --scanner-width: #{col-width(4, var(--max-width))}

    --qr-scanner-size: var(--scanner-width)

    display: grid
    grid-template-areas: ". . scanner" "text . scanner" "hero-form . scanner" ". . scanner"
    grid-template-columns: var(--text-width) var(--spacer-width) var(--scanner-width)
    grid-template-rows: 1fr auto auto 1fr
    gap: 0 var(--grid-gutter-width)
    justify-content: center

.code-search-layout--scanner
  grid-area: scanner

  position: relative

  +media-breakpoint-up($horizontal-code-search-hero-from)
    &::after
      content: ''
      display: block
      width: 65px
      height: 87px

      background: url(/var/www/radfahrausbildung_p/releases/20250205104635/app/assets/frontend/components/../../images/bird.svg) no-repeat center / contain

      position: absolute
      right: $size-s
      bottom: calc(100% - 8px)

.code-search-layout--text
  grid-area: text

.code-search-layout--hero-form
  grid-area: hero-form
  margin-top: $size-m

  +media-breakpoint-down($horizontal-code-search-hero-from)
    display: none

// Used when rendering WYSIWYG component in the backend
.mce-content-body
  img,
  video,
  audio,
  object,
  embed
    &[data-mce-selected]
      outline: 2px solid rgba(var(--bs-primary-rgb), 0.5)

  th,
  td
    &[data-mce-selected]
      background: rgba(var(--bs-primary-rgb), 0.25)

@import ../environment

.user-test-instruction
  --font-size: #{$small-font-size}
  font-size: var(--font-size)

  display: flex
  gap: var(--spacer-2)

  & + &
    margin-top: var(--spacer-4)

  +media-breakpoint-up(xl)
    --font-size: #{$font-size-base}

.user-test-instruction--icon
  --app-icon-size: calc(var(--font-size) + 0.2rem)
  color: $brand-dark-blue-300

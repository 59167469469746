@import ../environment

.authentication-teaser-links
  --bs-link-color: #{$gray-700}
  --bs-link-hover-color: #{$body-color}
  --app-icon-margin-right: var(--spacer-2)

  display: flex
  justify-content: center

  > *
    position: relative

  > * + *
    &::before
      content: ''
      position: absolute
      left: 0
      z-index: -1

      height: calc(1em * #{$line-height-base})
      width: 1px
      background: $brand-light-blue-200

// Google Fonts does not offer downloading WOFF2 files directly, though they are publicly available.
// We fetched them using https://google-webfonts-helper.herokuapp.com/fonts/nunito?subsets=latin

@font-face
  font-family: 'Nunito'
  font-style: normal
  font-weight: 400
  src: local(''), url('/var/www/radfahrausbildung_p/releases/20250205104635/app/assets/fonts/./nunito/nunito-v25-latin-regular.woff2') format('woff2')

@font-face
  font-family: 'Nunito'
  font-style: italic
  font-weight: 400
  src: local(''), url('/var/www/radfahrausbildung_p/releases/20250205104635/app/assets/fonts/./nunito/nunito-v25-latin-italic.woff2') format('woff2')

@font-face
  font-family: 'Nunito'
  font-style: normal
  font-weight: 700
  src: local(''), url('/var/www/radfahrausbildung_p/releases/20250205104635/app/assets/fonts/./nunito/nunito-v25-latin-700.woff2') format('woff2')

@font-face
  font-family: 'Nunito'
  font-style: italic
  font-weight: 700
  src: local(''), url('/var/www/radfahrausbildung_p/releases/20250205104635/app/assets/fonts/./nunito/nunito-v25-latin-700italic.woff2') format('woff2')

@import ../environment

.table-of-contents-section
  display: grid
  grid-template-columns: auto 1fr
  grid-template-rows: 1fr 1fr
  grid-template-areas: 'icon description' 'icon title'
  align-items: center
  column-gap: var(--spacer-3)

.table-of-contents-section--description
  grid-area: description

  font-size: $small-font-size
  color: $gray-600

.table-of-contents-section--title
  grid-area: title

  font-family: $headings-font-family
  font-weight: $headings-font-weight
  +font-size($h3-font-size)
  color: $dark

.table-of-contents-section--icon
  grid-area: icon

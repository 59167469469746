.mce-content-body img[data-mce-selected],
.mce-content-body video[data-mce-selected],
.mce-content-body audio[data-mce-selected],
.mce-content-body object[data-mce-selected],
.mce-content-body embed[data-mce-selected] {
  outline: 2px solid rgba(var(--bs-primary-rgb), 0.5);
}
.mce-content-body th[data-mce-selected],
.mce-content-body td[data-mce-selected] {
  background: rgba(var(--bs-primary-rgb), 0.25);
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcmFkZmFocmF1c2JpbGR1bmdfcC9yZWxlYXNlcy8yMDI1MDIwNTEwNDYzNS9hcHAvYXNzZXRzL2Zyb250ZW5kL2NvbXBvbmVudHMvdGlueW1jZV9leHQiLCJzb3VyY2VzIjpbIm1jZV9jb250ZW50X2JvZHkuc2FzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFPSTtBQUFBO0FBQUE7QUFBQTtBQUFBO0VBQ0U7O0FBSUY7QUFBQTtFQUNFIiwic291cmNlc0NvbnRlbnQiOlsiLy8gVXNlZCB3aGVuIHJlbmRlcmluZyBXWVNJV1lHIGNvbXBvbmVudCBpbiB0aGUgYmFja2VuZFxuLm1jZS1jb250ZW50LWJvZHlcbiAgaW1nLFxuICB2aWRlbyxcbiAgYXVkaW8sXG4gIG9iamVjdCxcbiAgZW1iZWRcbiAgICAmW2RhdGEtbWNlLXNlbGVjdGVkXVxuICAgICAgb3V0bGluZTogMnB4IHNvbGlkIHJnYmEodmFyKC0tYnMtcHJpbWFyeS1yZ2IpLCAwLjUpXG5cbiAgdGgsXG4gIHRkXG4gICAgJltkYXRhLW1jZS1zZWxlY3RlZF1cbiAgICAgIGJhY2tncm91bmQ6IHJnYmEodmFyKC0tYnMtcHJpbWFyeS1yZ2IpLCAwLjI1KVxuIl19 */
@import ../../environment

.link-muted
  $value: $text-muted

  // styles like Bootstrap's .link-*
  color: $value !important

  @if $link-shade-percentage != 0
    &:hover,
    &:focus
      color: if(color-contrast($value) == $color-contrast-light, shade-color($value, $link-shade-percentage), tint-color($value, $link-shade-percentage)) !important

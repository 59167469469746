@import ../../environment

.btn-link
  --bs-btn-border-radius: #{$border-radius-xs}
  --bs-btn-focus-shadow-rgb: #{rgb-values($brand-dark-blue-300)}

.btn-light
  +button-variant($white, $white)

.btn-outline-light
  +button-outline-variant($white, $white)

.btn-secondary,
.btn-outline-secondary,
.btn-light,
.btn-outline-light
  --bs-btn-color: var(--bs-link-color)
  --bs-btn-hover-color: var(--bs-link-hover-color)
  --bs-btn-active-color: var(--bs-link-hover-color)

@each $name, $color in $theme-colors
  .btn-#{$name}
    --bs-btn-disabled-color: var(--bs-white)
    --bs-btn-disabled-bg: var(--bs-gray-400)
    --bs-btn-disabled-border-color: var(--bs-gray-400)

  .btn-outline-#{$name}
    --bs-btn-disabled-color: var(--bs-gray-400)
    --bs-btn-disabled-bg: var(--bs-white)
    --bs-btn-disabled-border-color: var(--bs-gray-400)
    --bs-btn-disabled-opacity: 1

.input-group
  > .btn
    --bs-btn-border-color: var(--bs-gray-400)
    --bs-btn-active-border-color: var(--bs-btn-border-color)
    --bs-btn-hover-border-color: var(--bs-btn-border-color)
    --bs-btn-border-radius: var(--input-border-radius)

    &:not(:first-child)
      border-left-width: 0
    &:not(:last-child)
      border-right-width: 0

@import ../environment

$checkbox-width: px-to-rem(24px)

%header
  font-size: $smaller-font-size
  line-height: $smaller-font-size
  font-weight: $font-weight-bold
  color: $gray-600

%checkbox-container
  display: flex
  justify-content: center

%answer-cell
  .test-question-result.-no-answers &
    display: none

.test-question-result
  display: grid
  grid-template-columns: var(--spacer-3) var(--answers-column-width) var(--spacer-3) 1px var(--spacer-3) $checkbox-width 1ch 1fr
  grid-template-rows: auto repeat(var(--number-of-choices, 5), auto)
  grid-template-areas: "answers-header answers-header answers-header . . solutions-header solutions-header solutions-header" ". answer-checkbox . separator . solution-checkbox . label"
  row-gap: var(--spacer-2)
  --answers-column-width: #{$checkbox-width}

  +media-breakpoint-up(md)
    &.-with-translations
      --answers-column-width: max-content

  &.-no-answers
    grid-template-columns: $checkbox-width 1ch 1fr
    grid-template-areas: "solutions-header solutions-header solutions-header" "solution-checkbox . label"


.test-question-result--header-left
  grid-area: answers-header
  @extend %header
  @extend %answer-cell

  text-align: center
  padding: 0 var(--spacer-2)

.test-question-result--header-right
  grid-area: solutions-header
  @extend %header

  // align text to checkbox
  padding-left: 0.25em

.test-question-result--ruler
  grid-column: separator
  grid-row: 2 / -1
  background-color: $gray-300

  @extend %answer-cell

.test-question-result--selected
  grid-column: answer-checkbox
  @extend %checkbox-container
  @extend %answer-cell

.test-question-result--solution
  grid-column: solution-checkbox
  @extend %checkbox-container

.test-question-result--label
  grid-column: label

  &.-correct
    font-weight: $font-weight-bold
    color: $success

@import ../environment

.vertical-test-teaser
  display: grid
  grid-template-columns: 1fr
  grid-template-rows: auto 1fr auto
  grid-template-areas: "text" "action" "image"
  gap: $size-s

  background-color: $brand-green-50
  border-radius: $border-radius-md
  box-shadow: $shadow-sm-blue

  padding: $size-l $size-m 0

.vertical-test-teaser--text
  grid-area: text

.vertical-test-teaser--action
  grid-area: action

.vertical-test-teaser--image
  grid-area: image
  align-self: end

  width: 100%
  max-width: px-to-rem(320px)
  margin-inline: auto
  overflow: hidden
  > img
    width: 100%
    margin-bottom: -7%

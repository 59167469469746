$brand-dark-green: #05951b // DVW

// https://www.figma.com/file/DJDNOJiaxTaPvuNP8yj7Cm/Radfahrausbildung-UI%2FUX-Design?node-id=827%3A9871

$brand-green: #65C145
$brand-green-75: #EBF7E9
$brand-green-50: #F2FAF0
$brand-orange: #E7760E
$brand-orange-50: #FDF2E0
$brand-red: #DA0000
$brand-red-50: #FFE9E7
$brand-blue: #3C91E6
$brand-dark-blue: #040147

$brand-dark-blue-300: #686791
$brand-dark-blue-100: #B4B3C8
$brand-dark-blue-50: #E6E6ED

$brand-light-blue: #F4F7FE
$brand-light-blue-200: #DAE4FC
$brand-light-blue-100: #E8EEFD

$brand-orange-gradient-start: #FF9F0F
$brand-orange-gradient-end: #FF3535

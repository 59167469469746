@import ../environment

$horizontal-from: lg

.account-form-hero
  display: grid
  grid-template-columns: auto var(--container-content-width) auto
  grid-template-rows: var(--content-margin) auto var(--spacer-3) auto var(--content-margin) auto
  grid-template-areas: "background-start . ." ". text ." ". . ." ". avatar background-end" ". . ." ". form ."

  +media-breakpoint-up($horizontal-from)
    --left-width: #{col-width(5)}
    --right-width: #{col-width(7)}

    grid-template-columns: auto var(--left-width) var(--grid-gutter-width) var(--right-width) auto
    grid-template-rows: var(--content-margin) auto var(--spacer-5) auto 1fr
    grid-template-areas: "background-start . . . ." ". text . form ." ". . . form ." ". avatar . form background-end" ". . . form ."

    +media-breakpoint-up(xl)
      --left-width: #{col-width(6)}
      --right-width: #{col-width(6)}

.account-form-hero--background-hero
  grid-column: background-start / background-end
  grid-row: background-start / background-end

  > .hero
    height: 100%

.account-form-hero--text
  grid-area: text

  --bs-link-color: #{$brand-dark-blue}
  --bs-link-hover-color: #{$brand-dark-blue-300}

  text-align: center
  +media-breakpoint-up($horizontal-from)
    text-align: unset

.account-form-hero--avatar
  grid-area: avatar

  display: grid
  justify-items: center

  +media-breakpoint-up($horizontal-from)
    justify-items: start

.account-form-hero--form
  grid-area: form

@import ../environment

$banner-height: $size-s

=from-tablet
  +media-breakpoint-up($tablet-from)
    @content

=from-desktop
  +media-breakpoint-up($desktop-from)
    // The "desktop" viewport starts at a width which several Android tablets exceed.
    // Ideally, those tablets should still receive the tablet navigation/layout.
    .layout:not(.-tablet-device):not(.-phone-device) &
      @content

.layout
  min-height: 100vh
  display: flex
  flex-direction: column

  @media print
    min-height: auto
    display: block


.layout--banner
  z-index: 2
  +not-in-tests
    position: sticky
    top: 0
  &:not(:empty)
    --banner-height: #{$banner-height}


.layout--content
  flex-grow: 1
  isolation: isolate

  *:not(up-modal-content) > &
    // when not in modal

    &:not(.-has-hero)
      // If a hero is used, it sets that padding itself.
      padding-top: var(--content-margin)
    &:not(.-has-bottom-hero)
      // Bottom hero adds padding itself, and footer overlaps
      padding-bottom: var(--content-margin)

    &.-tall
      padding-top: 0
      padding-bottom: 0

      display: flex
      flex-direction: column

  --content-margin: var(--spacer-4)
  +from-desktop
    --content-margin: #{$size-2xl}

  ///////////////////////////////////////////////////////////////////////////
  // Custom Properties consumed by elements rendered inside the content area:
  ///////////////////////////////////////////////////////////////////////////

  /////////
  // Banner
  --banner-height: 0px /* 0px instead of 0 to avoid breaking `calc`s */
  .layout--banner:not(:empty) ~ &
    --banner-height: #{$banner-height}

  //////////////////////////////////
  // Navigation (desktop & floating)
  --navigation-height: #{$mobile-navigation-height}
  +from-tablet
    --navigation-height: #{$tablet-navigation-height}
  +from-desktop
    --navigation-height: #{$desktop-navigation-height}

  &.-hides-floating-navigation
    --navigation-height: 0px
    +from-desktop
      --navigation-height: #{$desktop-navigation-height}

  &.-full-screen-layout
    +media-breakpoint-down($fullscreen-test-ui-until)
      z-index: 1
      position: absolute

.layout--desktop-navigation
  display: none
  +from-desktop
    display: block
    z-index: 1

    box-shadow: 0 0 2px rgba($brand-dark-blue, .1)

    +not-in-tests
      position: sticky
      top: 0

  .layout--banner:not(:empty) ~ &
    top: #{$banner-height}


.layout--floating-navigation
  display: grid
  place-content: stretch

  position: sticky
  bottom: 0
  right: 0
  left: 0

  pointer-events: none
  > *
    pointer-events: auto

  +from-tablet
    place-content: center
    bottom: $size-xs

  +from-desktop
    display: none

  &:empty
    display: none

  opacity: 1
  transform: translateY(0)

  transition: brand-transition(opacity, transform)

  .layout--content.-hides-floating-navigation ~ &
    > *
      pointer-events: none

    opacity: 0

    $translate-y: $size-xs
    transform: translateY($translate-y)

    // negative margin to make the invisible element not consume any space
    margin-top: -#{$mobile-navigation-height + $translate-y}
    +from-tablet
      margin-top: -#{$tablet-navigation-height + $translate-y}

.layout--footer
  --border-radius: #{$border-radius}
  +from-tablet
    --border-radius: #{$border-radius-lg}

  --background: #{$light}

  border-radius: var(--border-radius) var(--border-radius) 0 0
  isolation: isolate // ensure we overlay content, if overlaying any
  position: relative

  background-color: var(--background)

  .layout--content.-has-bottom-hero ~ &,
  .layout--wallpaper.-light ~ &
    --background: #{$white}

  .layout--content.-has-bottom-hero ~ &
    margin-top: calc(-1 * var(--border-radius))

  +from-tablet
    &::after
      .layout.-has-navigation &
        content: ''
        height: $tablet-navigation-height
        position: absolute
        top: 100%
        left: 0
        width: 100%
        background-color: var(--background)

      .layout--content.-hides-floating-navigation ~ &
        display: none

      +from-desktop
        display: none


.layout--wallpaper
  z-index: -9999
  position: fixed
  inset: 0
  user-select: none

  &.-light
    background-color: $light

.layout--desktop-navigation,
.layout--floating-navigation,
.layout--footer
  @media print
    display: none

@use "sass:math"

\:root
  --bs-body-text-align: start

  @each $scale, $width in $spacers
    --spacer-#{$scale}: #{$width}

  $breakpoint-names: map-keys($grid-breakpoints)
  @for $i from 1 through length($breakpoint-names)
    $name: nth($breakpoint-names, $i)
    $container-width: map-get($container-max-widths, $name)

    @if $container-width == 100%
      $next-name: nth($breakpoint-names, $i + 1)
      $next-width: map-get($grid-breakpoints, $next-name)
      --container-max-#{$name}: calc(#{$next-width} - 2 * var(--container-padding))
    @else if $container-width
      --container-max-#{$name}: #{map-get($container-max-widths, $name)}

  --link-decoration: #{$--link-decoration}
  --link-hover-decoration: #{$--link-hover-decoration}

  --border-radius: #{$border-radius}
  --box-shadow: #{$box-shadow}
  --box-shadow-sm: #{$box-shadow-sm}
  --box-shadow-lg: #{$box-shadow-lg}

  --input-transition: #{$input-transition}
  --input-border-radius: #{$input-border-radius}
  --input-btn-focus-box-shadow: #{$input-btn-focus-box-shadow}

  --nav-link-padding-x: #{$nav-link-padding-x}
  --nav-link-padding-y: #{$nav-link-padding-y}

  --form-group-spacer: #{$form-group-spacer}

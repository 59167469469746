@import ../config/mixins

.flashes
  z-index: 1999 // below Unpoly backdrops

  +not-in-tests
    position: sticky
    top: 2rem

.flashes--flash-messages
  +not-in-tests
    position: absolute
    top: 0
    left: 50%
    transform: translateX(-50%)

  width: max-content
  max-width: 100%
  padding: 0 1rem

  pointer-events: none // each .flash-message will change that to "auto"

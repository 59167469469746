=authentication-box($large-padding-x: $size-2xl)
  background-color: $white
  border-radius: $border-radius-md
  box-shadow: $shadow-sm-blue
  padding: var(--authentication-box-padding-y) var(--authentication-box-padding-x)

  --authentication-box-padding-y: #{$size-m}
  --authentication-box-padding-x: #{$size-s}
  +media-breakpoint-up($tablet-from)
    --authentication-box-padding-x: #{$large-padding-x}

=authentication-box--heading($margin, $color: $headings-color)
  +rfs($h2-font-size, --default-font-size)
  font-size: var(--heading-font-size, var(--default-font-size))
  font-family: $headings-font-family
  font-weight: $headings-font-weight
  line-height: $headings-line-height
  color: $color

  text-align: center
  margin-top: 0
  margin-bottom: $margin

=authentication-box--buttons($margin)
  margin-top: $margin

  display: flex
  flex-direction: column
  gap: $size-xs

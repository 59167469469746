@import ../environment

.btn-avatar-choice
  padding: 0
  border-width: px-to-rem(3px)
  +button-variant($light, $light, $active-border: $primary)
  --bs-btn-focus-shadow-rgb: #{to-rgb($primary)}

  position: relative
  width: 100%

  &::before
    content: ''
    display: block
    padding-top: 100% // square

  > img
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

    border-radius: 100%

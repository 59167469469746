@import ../environment

$tile-size: px-to-rem(200px)
$gap: px-to-rem(13px)

$padding-x: 1rem
$padding-y: $gap

$icon-size: px-to-rem(80px)
$progress-height: px-to-rem(8px)

$title-lines: 2
$title-line-height: 1.125
$title-height: 1rem * $title-line-height * $title-lines

.course-unit-tile
  --size: var(--course-unit-tile--size, #{px-to-rem(184px)})

  width: var(--size)
  height: var(--size)
  background-color: $white
  border-radius: px-to-rem(30px)

  display: grid
  padding: $padding-y $padding-x
  grid-template-columns: 1fr
  grid-template-rows: 1fr $title-height $progress-height
  grid-template-areas: "icon" "title" "progress"
  gap: $gap
  justify-items: center

  +focusable-tile
  +ribbon-container

.course-unit-tile--icon
  grid-area: icon
  align-self: end

  --animated-icon-size: #{px-to-rem(80px)}

.course-unit-tile--title
  grid-area: title

  color: $brand-dark-blue
  font-weight: $font-weight-bold
  text-align: center
  line-height: $title-line-height

  +line-clamp($title-lines)

.course-unit-tile--progress
  grid-area: progress

  width: $icon-size
  height: $progress-height
  border-radius: 100vmax
  background: $brand-light-blue-200
  overflow: hidden

  position: relative
  &::before
    content: ''
    position: absolute
    top: 0
    left: 0
    bottom: 0
    width: var(--progress)
    background-color: $primary

.course-unit-tile--completed
  --app-icon-size: #{px-to-rem(18px)}

  position: absolute
  top: $padding-y
  right: $padding-x

  font-weight: $font-weight-bold
  color: $primary
  background-color: $secondary
  line-height: 1

  padding: spacer(1) spacer(2)
  border-radius: 100vmax

.course-unit-tile--ribbon
  +ribbon($course-unit-ribbon-colors)

@import ../environment

.new-account
  display: grid

.new-account--heading
  margin: 0 0 $size-xs

.new-account--intro
  margin: 0 0 $size-m

.new-account--info
  margin: 0 0 $size-m

.new-account--cancel
  margin: $size-2xs 0 0
  text-align: center

  --bs-link-color: #{$gray-700}
  --bs-link-hover-color: #{$body-color}

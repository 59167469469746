@import ../environment

$horizontal-from: lg

.dashboard-topics-overview
  display: grid

  grid-template-columns: 1fr
  grid-template-rows: auto auto auto
  grid-template-areas: "title" "image" "topics"
  gap: px-to-rem(36px) var(--grid-gutter-width)

  +media-breakpoint-up($horizontal-from)
    grid-template-columns: #{col-width(4)} 1fr
    grid-template-rows: auto 1fr
    grid-template-areas: "image title" "image topics"

.dashboard-topics-overview--title
  grid-area: title

  margin: 0
  text-align: center

  +media-breakpoint-up($horizontal-from)
    text-align: unset

.dashboard-topics-overview--image
  grid-area: image

  width: 100%
  max-width: px-to-rem(320px)
  margin-inline: auto
  > img
    width: 100%

.dashboard-topics-overview--topics
  grid-area: topics

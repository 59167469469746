@import ../environment

=vertical
  +media-breakpoint-down(lg)
    @content

.demo-teaser
  background-color: $brand-light-blue-100
  border-radius: $border-radius

  display: grid
  grid-template-areas: ". . image" "heading . image" "text text image" ". . image"
  grid-template-columns: min-content 1fr 1fr
  grid-template-rows: spacer(5) auto auto spacer(5)

  padding: 0 spacer(5)

  +vertical
    grid-template-areas: ". heading ." "text text text" "image image image"
    grid-template-columns: 1fr min-content 1fr
    grid-template-rows: auto auto auto

    padding: spacer(5) spacer(3) 0
    text-align: center

.demo-teaser--heading
  grid-area: heading
  align-self: end

  white-space: nowrap
  isolation: isolate

  > h2
    margin-bottom: spacer(2)
    +media-breakpoint-down(sm)
      font-size: $h3-font-size
      font-weight: bold

  > svg
    pointer-events: none
    position: relative
    z-index: -1

    margin-top: -35px
    height: 40px

    max-width: 100%
    width: 110%

    stroke-width: 8
    stroke: $yellow
    stroke-linecap: round
    fill: none

.demo-teaser--text
  grid-area: text


.demo-teaser--image
  grid-area: image
  align-self: end

  margin-left: auto
  margin-right: 30%
  +vertical
    margin-right: auto

  padding: 2rem 1.5rem 0
  overflow: hidden

  > img
    max-height: px-to-rem(300px)
    transform: rotate(-2.5deg)
    margin-bottom: -.5rem
    filter: drop-shadow(.2rem .2rem .4rem rgba($brand-dark-blue, .2))

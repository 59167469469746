@import ../environment

.nav-signed-out
  --app-icon-size: #{$size-m}
  --bs-nav-link-padding-y: 0

  display: grid
  grid-template-columns: 1fr auto 1fr
  grid-template-areas: "internal brand public"
  gap: var(--spacer-3)
  align-items: center

  height: var(--header-height)
  padding-top: var(--header-nav-padding-y)
  padding-bottom: var(--header-nav-padding-y)

.nav-signed-out--internal
  grid-area: internal

  display: flex
  justify-content: start

.nav-signed-out--brand
  grid-area: brand

.nav-signed-out--public
  grid-area: public

  display: flex
  justify-content: end

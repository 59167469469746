@import ../environment

.test-navigation
  display: grid
  gap: var(--spacer-3)

  +media-breakpoint-up($fullscreen-test-ui-until)
    display: flex
    flex-wrap: wrap
    align-items: center
    justify-content: space-between

@import ../environment

.btn-circle
  --size: #{2 * $size-xs}

  --bs-btn-border-radius: 100vmax
  --bs-btn-padding-x: 0
  --bs-btn-padding-y: 0

  width: var(--size)
  height: var(--size)
  overflow: hidden

  display: inline-grid
  place-content: center

@import ../environment

$horizontal-from: $horizontal-question-layout-from
$max-explanation-height: max(15vh, 7rem)

.question-container
  height: 100%
  --vertical-spacer: #{$size-xs}

  display: flex
  flex-direction: column

  +media-breakpoint-up($horizontal-from)
    align-items: center

  &:not(.-vertical-layout)
    +media-breakpoint-up($horizontal-from)
      --vertical-spacer: #{$size-s}

      display: grid
      grid-template-columns: 1fr 1fr
      grid-template-areas: "asset question" "explanation explanation"
      gap: var(--spacer-4) $size-xl

      &.-no-asset
        max-width: map-get($grid-breakpoints, sm)
        margin-inline: auto
        grid-template-columns: 1fr
        grid-template-areas: "question" "explanation"


.question-container--asset
  grid-area: asset
  width: 100%
  max-width: var(--container-max-sm)

  +media-breakpoint-down($horizontal-from)
    width: fit-content
    margin: auto

    img,
    video
      +mobile-course-ui-asset-height
      min-height: 60px
      max-height: $max-asset-height-on-mobile-slides
      object-fit: contain

  +media-breakpoint-down($fullscreen-course-ui-until)
    & .image
      border-radius: 0


.question-container--question
  --fade-out-size: #{$size-xl}
  --padding: var(--container-padding)

  grid-area: question

  padding-top: var(--vertical-spacer)
  padding-bottom: var(--fade-out-size) // avoid overlapping content which does not require a scrollbar; in effect, this causes scrollbars for contents which would fit just barely. that is okay for us.

  flex-grow: 1 // become the dominant flex child; also pushes .question-container--explanation to the bottom

  +media-breakpoint-down($fullscreen-course-ui-until)
    max-height: 100%
    overflow: auto

    padding-left: var(--padding)
    padding-right: var(--padding)
    +scrollbar-area(var(--padding))
    +styled-scrollbar($size: $size-4xs, $margin: var(--padding))

    // fade out overflowing contents at the bottom, to give users a hint that they can scroll
    --fade-out-mask-image: linear-gradient(to top, transparent 0, black var(--fade-out-size))
    --fade-out-mask: var(--fade-out-mask-image) left top / 100% 100% no-repeat

    // avoid fading out the scrollbar but putting a mask with full visibility (i.e. a black area) over it
    --scrollbar-mask-image: linear-gradient(to top, black 0%, black 100%)
    --scrollbar-mask: var(--scrollbar-mask-image) right bottom / var(--padding) 100% no-repeat

    --mask: var(--scrollbar-mask), var(--fade-out-mask)

    -webkit-mask: var(--mask)
    mask: var(--mask)

  +media-breakpoint-up($horizontal-from)
    padding: 0
    margin: 0

    .question-container.-vertical-layout &
      margin-top: var(--vertical-spacer)

.question-container--explanation
  grid-area: explanation
  align-self: stretch

  +media-breakpoint-down($fullscreen-course-ui-until)
    background-color: $brand-green-50
    min-height: calc(2rem * var(--bs-body-line-height) + 2 * var(--container-padding) + var(--spacer-2))

    display: grid // fit children to allow them to scroll on overflow

  +media-breakpoint-up($horizontal-from)
    .question-container.-vertical-layout &
      margin-top: var(--vertical-spacer)

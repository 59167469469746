@import ../environment

$horizontal-from: md

.test-question-result-preview
  display: grid
  grid-template-columns: auto 1fr auto
  grid-template-areas: 'state-icon state-label toggle-icon' 'question-text question-text question-text'
  gap: var(--spacer-1) var(--spacer-2)

  +media-breakpoint-up($horizontal-from)
    grid-template-areas: 'state-icon state-label toggle-icon' 'state-icon question-text toggle-icon'
    grid-column-gap: var(--spacer-4)

.test-question-result-preview--icon
  grid-area: state-icon
  align-self: center

  display: flex
  align-items: center
  justify-content: center

  +media-breakpoint-up($horizontal-from)
    border-radius: 100vmax
    width: $size-xl
    height: $size-xl

  &.-incorrect
    color: $danger
    +media-breakpoint-up($horizontal-from)
      background-color: $brand-red-50

  &.-correct
    color: $brand-green
    +media-breakpoint-up($horizontal-from)
      background-color: $brand-green-50

  --app-icon-size: #{$size-xs}

.test-question-result-preview--state
  grid-area: state-label

  color: $gray-600

  line-height: 1.4
  +media-breakpoint-up($horizontal-from)
    line-height: 1.2

.test-question-result-preview--expand
  grid-area: toggle-icon
  align-self: center

  line-height: 1.2

.test-question-result-preview--question
  grid-area: question-text

  font-weight: $font-weight-bold
  font-size: $lead-font-size
  font-family: $headings-font-family
  color: $brand-dark-blue
  line-height: 1.2

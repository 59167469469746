@import ../environment

.user-test-pagination
  display: flex
  flex-wrap: nowrap
  overflow: auto

  max-width: var(--pagination-width)
  padding-bottom: var(--spacer-2)

  --font-size: #{px-to-rem(13px)}
  --button-size: #{px-to-rem(35px)}
  --circle-size: #{px-to-rem(8px)}
  --pagination-width: calc(100vw - var(--container-padding) * 2)

  +styled-scrollbar($size: $size-4xs)

  +media-breakpoint-up($fullscreen-test-ui-until)
    background: $brand-light-blue-100
    border-radius: calc(var(--button-size) / 2)
    padding-bottom: 0

  +media-breakpoint-up(xl)
    --button-size: #{px-to-rem(40px)}
    --circle-size: #{px-to-rem(9px)}
    flex-wrap: nowrap

.user-test-pagination--button
  font-size: var(--font-size)
  font-weight: $font-weight-bold
  line-height: 1
  color: $brand-dark-blue

  width: var(--button-size)
  height: var(--button-size)
  border-radius: calc(var(--button-size) / 2)
  background: transparent
  border: none

  display: inline-flex
  flex-direction: column
  align-items: center
  justify-content: center
  gap: .25em
  flex-shrink: 0

  +media-breakpoint-up($fullscreen-test-ui-until)
    flex-shrink: 1

  &:hover
    &:not(.-active)
      color: $brand-dark-blue-300

  &::after
    content: ''
    display: inline
    width: var(--circle-size)
    height: var(--circle-size)
    border: 1px solid $brand-dark-blue-100
    border-radius: 100vmax

  &.-active
    background: $white
    box-shadow: $shadow-sm-blue

  &.-finished
    &::after
      background-color: $brand-blue
      border-color: $brand-blue

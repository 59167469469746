.animated-icon {
  display: inline-block;
  vertical-align: middle;
  width: var(--animated-icon-size, 100%);
}
.animated-icon:empty::before {
  content: "";
  display: block;
  padding-top: 100%;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcmFkZmFocmF1c2JpbGR1bmdfcC9yZWxlYXNlcy8yMDI1MDIwNTEwNDYzNS9hcHAvYXNzZXRzL3NoYXJlZC9jb21wb25lbnRzIiwic291cmNlcyI6WyJhbmltYXRlZF9pY29uLnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBO0VBQ0E7O0FBR0U7RUFDRTtFQUNBO0VBQ0EiLCJzb3VyY2VzQ29udGVudCI6WyIuYW5pbWF0ZWQtaWNvblxuICBkaXNwbGF5OiBpbmxpbmUtYmxvY2tcbiAgdmVydGljYWwtYWxpZ246IG1pZGRsZVxuICB3aWR0aDogdmFyKC0tYW5pbWF0ZWQtaWNvbi1zaXplLCAxMDAlKVxuXG4gICY6ZW1wdHlcbiAgICAmOjpiZWZvcmVcbiAgICAgIGNvbnRlbnQ6ICcnXG4gICAgICBkaXNwbGF5OiBibG9ja1xuICAgICAgcGFkZGluZy10b3A6IDEwMCUgLy8gcmVsYXRpdmUgdG8gY29udGFpbmVyICp3aWR0aCogPT4gMTAwJSA9IHNxdWFyZVxuIl19 */
@import ../environment

.test-question-result-card
  border: $border-width solid $gray-300
  border-radius: $border-radius

  & + &
    margin-top: var(--spacer-4)

.test-question-result-card--header
  cursor: pointer
  padding: var(--spacer-3)

.test-question-result-card--body
  border-top: $border-width solid $gray-300

  display: grid
  align-items: center
  grid-template-columns: 1fr
  grid-template-areas: 'image' 'answers' 'explanation'

  +media-breakpoint-up(lg)
    grid-template-columns: auto 1fr
    grid-template-areas: 'image answers' 'explanation explanation'

.test-question-result-card--image
  grid-area: image

  +media-breakpoint-up(lg)
    margin: var(--spacer-3)
    width: px-to-rem(110px)

  > img
    width: 100%

.test-question-result-card--answers
  grid-area: answers
  padding: var(--spacer-3) var(--spacer-3) 0

.test-question-result-card--explanation
  grid-area: explanation
  padding: var(--spacer-3)

@import ../environment

.course-overview
  position: relative

  +media-breakpoint-up(md)
    display: grid
    grid-template-columns: 3rem 1fr auto
    grid-template-rows: auto
    grid-template-areas: "icon title action"
    gap: var(--spacer-3)
    align-items: center

  & + &
    margin-top: var(--spacer-3)

  &:hover,
  &:has(:focus-visible)
    &::before
      content: ''
      position: absolute
      inset: calc(-1 * var(--spacer-2))
      background: rgba(var(--bs-primary-rgb), 0.1)
      z-index: -1

.course-overview--icon
  display: none

  +media-breakpoint-up(md)
    display: block
    grid-area: icon
    font-size: 3rem
    line-height: 1

.course-overview--title
  +media-breakpoint-up(md)
    grid-area: title

.course-overview--action
  +media-breakpoint-up(md)
    grid-area: action
    margin-left: auto

@import ../environment

$horizontal-from: lg

.course-unit-read-card
  display: flex
  flex-direction: column
  gap: var(--spacer-4)
  align-items: center
  justify-content: end

  +media-breakpoint-up($horizontal-from)
    background: $white
    border-radius: $border-radius
    box-shadow: $shadow-sm-blue
    padding: var(--spacer-4) var(--spacer-2)


.course-unit-read-card--icon
  +media-breakpoint-down($horizontal-from)
    display: none

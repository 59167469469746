.nav-signed-out {
  --app-icon-size: 2.25rem;
  --bs-nav-link-padding-y: 0;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: "internal brand public";
  gap: var(--spacer-3);
  align-items: center;
  height: var(--header-height);
  padding-top: var(--header-nav-padding-y);
  padding-bottom: var(--header-nav-padding-y);
}

.nav-signed-out--internal {
  grid-area: internal;
  display: flex;
  justify-content: start;
}

.nav-signed-out--brand {
  grid-area: brand;
}

.nav-signed-out--public {
  grid-area: public;
  display: flex;
  justify-content: end;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcmFkZmFocmF1c2JpbGR1bmdfcC9yZWxlYXNlcy8yMDI1MDIwNTEwNDYzNS9hcHAvYXNzZXRzL2Zyb250ZW5kL2NvbXBvbmVudHMiLCJzb3VyY2VzIjpbIm5hdl9zaWduZWRfb3V0LnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRTtFQUNBO0VBRUE7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUVBO0VBQ0E7RUFDQTs7O0FBRUY7RUFDRTtFQUVBO0VBQ0E7OztBQUVGO0VBQ0U7OztBQUVGO0VBQ0U7RUFFQTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiQGltcG9ydCAuLi9lbnZpcm9ubWVudFxuXG4ubmF2LXNpZ25lZC1vdXRcbiAgLS1hcHAtaWNvbi1zaXplOiAjeyRzaXplLW19XG4gIC0tYnMtbmF2LWxpbmstcGFkZGluZy15OiAwXG5cbiAgZGlzcGxheTogZ3JpZFxuICBncmlkLXRlbXBsYXRlLWNvbHVtbnM6IDFmciBhdXRvIDFmclxuICBncmlkLXRlbXBsYXRlLWFyZWFzOiBcImludGVybmFsIGJyYW5kIHB1YmxpY1wiXG4gIGdhcDogdmFyKC0tc3BhY2VyLTMpXG4gIGFsaWduLWl0ZW1zOiBjZW50ZXJcblxuICBoZWlnaHQ6IHZhcigtLWhlYWRlci1oZWlnaHQpXG4gIHBhZGRpbmctdG9wOiB2YXIoLS1oZWFkZXItbmF2LXBhZGRpbmcteSlcbiAgcGFkZGluZy1ib3R0b206IHZhcigtLWhlYWRlci1uYXYtcGFkZGluZy15KVxuXG4ubmF2LXNpZ25lZC1vdXQtLWludGVybmFsXG4gIGdyaWQtYXJlYTogaW50ZXJuYWxcblxuICBkaXNwbGF5OiBmbGV4XG4gIGp1c3RpZnktY29udGVudDogc3RhcnRcblxuLm5hdi1zaWduZWQtb3V0LS1icmFuZFxuICBncmlkLWFyZWE6IGJyYW5kXG5cbi5uYXYtc2lnbmVkLW91dC0tcHVibGljXG4gIGdyaWQtYXJlYTogcHVibGljXG5cbiAgZGlzcGxheTogZmxleFxuICBqdXN0aWZ5LWNvbnRlbnQ6IGVuZFxuIl19 */
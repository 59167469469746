.language-select {
  max-width: 24rem;
}

.language-select--select {
  width: max-content;
  outline: none;
  border: none;
  background-color: #fff;
  cursor: pointer;
  font-weight: bold;
  color: #65C145;
  padding: 0.5rem 1rem;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3Zhci93d3cvcmFkZmFocmF1c2JpbGR1bmdfcC9yZWxlYXNlcy8yMDI1MDIwNTEwNDYzNS9hcHAvYXNzZXRzL2Zyb250ZW5kL2NvbXBvbmVudHMiLCJzb3VyY2VzIjpbImxhbmd1YWdlX3NlbGVjdC5zYXNzIiwiLi4vLi4vc2hhcmVkL2NvbmZpZy9fYnJhbmRfY29sb3JzLnNhc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFDRTs7O0FBRUY7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBRUE7RUFDQSxPQ1RZO0VEVVoiLCJzb3VyY2VzQ29udGVudCI6WyJAaW1wb3J0IC4uL2Vudmlyb25tZW50XG5cbi5sYW5ndWFnZS1zZWxlY3RcbiAgbWF4LXdpZHRoOiAyNHJlbVxuXG4ubGFuZ3VhZ2Utc2VsZWN0LS1zZWxlY3RcbiAgd2lkdGg6IG1heC1jb250ZW50XG4gIG91dGxpbmU6IG5vbmVcbiAgYm9yZGVyOiBub25lXG4gIGJhY2tncm91bmQtY29sb3I6ICNmZmZcbiAgY3Vyc29yOiBwb2ludGVyXG5cbiAgZm9udC13ZWlnaHQ6IGJvbGRcbiAgY29sb3I6ICRsaW5rLWNvbG9yXG4gIHBhZGRpbmc6IHNwYWNlcigyKSBzcGFjZXIoMylcbiIsIiRicmFuZC1kYXJrLWdyZWVuOiAjMDU5NTFiIC8vIERWV1xuXG4vLyBodHRwczovL3d3dy5maWdtYS5jb20vZmlsZS9ESkROT0ppYXhUYVB2dU5QOHlqN0NtL1JhZGZhaHJhdXNiaWxkdW5nLVVJJTJGVVgtRGVzaWduP25vZGUtaWQ9ODI3JTNBOTg3MVxuXG4kYnJhbmQtZ3JlZW46ICM2NUMxNDVcbiRicmFuZC1ncmVlbi03NTogI0VCRjdFOVxuJGJyYW5kLWdyZWVuLTUwOiAjRjJGQUYwXG4kYnJhbmQtb3JhbmdlOiAjRTc3NjBFXG4kYnJhbmQtb3JhbmdlLTUwOiAjRkRGMkUwXG4kYnJhbmQtcmVkOiAjREEwMDAwXG4kYnJhbmQtcmVkLTUwOiAjRkZFOUU3XG4kYnJhbmQtYmx1ZTogIzNDOTFFNlxuJGJyYW5kLWRhcmstYmx1ZTogIzA0MDE0N1xuXG4kYnJhbmQtZGFyay1ibHVlLTMwMDogIzY4Njc5MVxuJGJyYW5kLWRhcmstYmx1ZS0xMDA6ICNCNEIzQzhcbiRicmFuZC1kYXJrLWJsdWUtNTA6ICNFNkU2RURcblxuJGJyYW5kLWxpZ2h0LWJsdWU6ICNGNEY3RkVcbiRicmFuZC1saWdodC1ibHVlLTIwMDogI0RBRTRGQ1xuJGJyYW5kLWxpZ2h0LWJsdWUtMTAwOiAjRThFRUZEXG5cbiRicmFuZC1vcmFuZ2UtZ3JhZGllbnQtc3RhcnQ6ICNGRjlGMEZcbiRicmFuZC1vcmFuZ2UtZ3JhZGllbnQtZW5kOiAjRkYzNTM1XG4iXX0= */
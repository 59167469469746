@import ../environment

.grid-game-container
  --padding: var(--container-padding)

  +media-breakpoint-down($fullscreen-course-ui-until)
    max-height: 100%
    padding: var(--padding)

    overflow: auto
    +scrollbar-area(var(--padding))
    +styled-scrollbar($size: $size-4xs, $margin: var(--padding))
